import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Checkbox,
    Container,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    Stack,
    ButtonBase,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enIE } from "date-fns/locale";

import CheckShow from "./shared/CheckShow";

import Colours from "../helpers/colours";
import {
    COMPARISON_FILTER,
    CANCELLATIONS_FILTER,
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
    GROUPs,
    PRESET_CHOOSE_MONTH,
    PRESET_CUSTOM,
    PRESET_GROUP,
    PRESET_MONTH_TO_DATE,
    PresetDates,
    formatDate,
    process_,
    DEFAULT_COMPARISON,
    PRESET_ARRIVAL_SELECTED_PERIOD,
    PRESET_ARRIVAL_GROUP,
    PresetArrivalDates,
    PRESET_ARRIVAL_CUSTOM,
    PRESET_ARRIVAL_CHOOSE_MONTH,
    PRESET_ARRIVAL_DEFAULT,
} from "../helpers/filters";
import Icons from "../helpers/icons";
import { fullExport } from "../helpers/export";
import { getYearsBefore, MAX_DATE, MIN_DATE } from "../helpers/dates";

import FiltersByType, { FiltersLine } from "./FiltersByType";
import ToggleButtonTitled from "./ToggleButtonTitled";
import TextPulledAgo from "./TextPulledAgo";
import DateRangePicker from "./DateRangePicker";
// import RefreshButton from "./RefreshButton";

import { ARO_ADMIN } from "../configuration";
import { setJS } from "../actions/globalReportFilters";
import { loadOne } from "../actions/PDFExport";

//   _____ ___ _   _____ _____ ____  ____  ____  _____ ____   ___  ____ _____
//  |  ___|_ _| | |_   _| ____|  _ \/ ___||  _ \| ____|  _ \ / _ \|  _ \_   _|
//  | |_   | || |   | | |  _| | |_) \___ \| |_) |  _| | |_) | | | | |_) || |
//  |  _|  | || |___| | | |___|  _ < ___) |  _ <| |___|  __/| |_| |  _ < | |
//  |_|   |___|_____|_| |_____|_| \_\____/|_| \_\_____|_|    \___/|_| \_\|_|
const ITEM_HEIGHT = "50px";
// This shows all the allowed filters and metrics to select for the reports
const FiltersReport = ({
    setProcessed = (v) => {},
    hide = [],
    PDF = false,
    defaultValues = {},
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [updateKey, setUpdateKey] = useState(0);

    // SET DEFAULT VALUES
    useEffect(() => {
        let aux = defaultValues;
        let newJS = { ...js };
        if ("presetDate" in defaultValues) {
            newJS = handlePresetChange(defaultValues["presetDate"]);
            delete aux.presetDate;
        }
        if ("filters" in defaultValues) {
            newJS = {
                ...newJS,
                filters: { ...newJS.filters, ...aux.filters },
            };
            delete aux.filters;
        }

        dispatch(
            setJS({
                ...newJS,
                ...aux,
            })
        );
    }, []);

    useEffect(() => {
        if (!PDF) {
            dispatch(
                loadOne(<FiltersReport PDF={true} />, "Filters", hotelID, {
                    duplicable: false,
                })
            );
        }
    }, [PDF]);

    useEffect(() => {
        setUpdateKey(updateKey + 1);
    }, []);
    const { dataBookings } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const [processedData, setProcessedData] = useState([]);

    const [lenProcessed, setLenProcessed] = useState(0);

    const [auxPresetDate, setAuxPresetDate] = useState({});
    const [auxPresetArrivalDate, setAuxPresetArrivalDate] = useState({});
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openArrivalDatePicker, setOpenArrivalDatePicker] = useState(false);

    useEffect(() => {
        // If we set a presetDate, update from and to to match
        if (
            js.presetDate === PRESET_CHOOSE_MONTH &&
            Object.keys(auxPresetDate).length > 0
        ) {
            const { fromDay, toDay } = PresetDates(
                js.presetDate,
                auxPresetDate
            );
            dispatch(
                setJS({
                    ...js,
                    fromDay,
                    toDay,
                })
            );
        }
    }, [auxPresetDate]);

    useEffect(() => {
        // If we set a presetArrivalDate, update fromArrivalDate and toArrivalDate to match
        if (
            js.presetArrivalDate === PRESET_ARRIVAL_CHOOSE_MONTH &&
            Object.keys(auxPresetArrivalDate).length > 0
        ) {
            const { fromArrivalDay, toArrivalDay } = PresetArrivalDates(
                js.presetArrivalDate,
                auxPresetArrivalDate
            );
            dispatch(
                setJS({
                    ...js,
                    fromArrivalDay,
                    toArrivalDay,
                })
            );
        }
    }, [auxPresetArrivalDate]);

    useEffect(() => {
        if (dataBookings.status === "loaded") {
            let { entries: process_out, len } = process_({
                js: js,
                dataBookings,
                hotelID,
            });
            setLenProcessed(len);
            setProcessedData(process_out);
            setProcessed(process_out);
        }
    }, [js, dataBookings]);

    // useEffect(() => {}, [processedData]);

    const handleExport = () => {
        const compileData = (config) => {
            let { entries: process_out, len } = process_({
                js: config,
                dataBookings,
                hotelID,
            });

            return { process_out, len };
        };

        const groupBys = [
            "Country",
            "OfferTitle",
            "RoomName",
            "Type",
            "VoucherName",
            "",
        ];

        let auxConfigs = {};

        groupBys.forEach((groupBy) => {
            let updatedConfigs = { ...js };
            updatedConfigs.groupBy = [groupBy]; // Update the groupBy property with the current groupBy value
            auxConfigs[groupBy] = updatedConfigs; // Store the updated object using the current groupBy value as key
        });

        const processedData = {};
        let totalLength = 0;
        Object.entries(auxConfigs).forEach(([configName, config]) => {
            const { process_out, len } = compileData(config);
            processedData[configName] = process_out;
            totalLength += len;
        });

        fullExport(js, processedData, hotelID);
    };

    const handlePresetChange = (presetValue) => {
        const isOpen = presetValue === PRESET_CUSTOM;
        setOpenDatePicker(isOpen);

        const preset = isOpen ? PRESET_MONTH_TO_DATE : presetValue;
        const { fromDay, toDay } = PresetDates(preset, {
            year: new Date().getUTCFullYear(),
            month: new Date().getUTCMonth(),
        });

        // If Arrival is selected period, update when time period changes
        if (js.presetArrivalDate === PRESET_ARRIVAL_SELECTED_PERIOD) {
            const { fromArrivalDay, toArrivalDay } = PresetArrivalDates(
                PRESET_ARRIVAL_SELECTED_PERIOD,
                { js: { fromDay, toDay } }
            );
            return {
                ...js,
                fromDay,
                toDay,
                fromArrivalDay,
                toArrivalDay,
                presetDate: presetValue,
            };
        }

        return { ...js, fromDay, toDay, presetDate: presetValue };
        // dispatch(setJS({ ...js, fromDay, toDay, presetDate: presetValue }));
    };

    const handlePresetArrivalChange = (presetArrivalValue) => {
        const isOpen = presetArrivalValue === PRESET_ARRIVAL_CUSTOM;
        setOpenArrivalDatePicker(isOpen);

        const preset = isOpen ? PRESET_ARRIVAL_DEFAULT : presetArrivalValue;
        const { fromArrivalDay, toArrivalDay } = PresetArrivalDates(preset, {
            year: new Date().getUTCFullYear(),
            month: new Date().getUTCMonth(),
            js,
        });
        return {
            ...js,
            fromArrivalDay,
            toArrivalDay,
            presetArrivalDate: presetArrivalValue,
        };
        // dispatch(setJS({ ...js, fromDay, toDay, presetDate: presetValue }));
    };

    return (
        <>
            <Container
                maxWidth={false}
                // component={HoverPaper}
                // sx={{ p: 2 }}
            >
                {!PDF && (
                    <Stack direction="row" justifyContent="flex-end">
                        <Box
                            sx={{
                                p: 1,
                                textAlign: "right",
                                fontSize: "0.8em",
                                color: Colours.primary,
                            }}
                        >
                            <ButtonBase
                                onClick={handleExport}
                                sx={{ fontFamily: "Montserrat" }}
                            >
                                <Icons.ArrowFullDown
                                    sx={{ fontSize: "1em", mr: "0.5em" }}
                                />
                                Download excel
                            </ButtonBase>
                        </Box>
                        <Box
                            sx={{
                                p: 1,
                                textAlign: "right",
                                fontSize: "0.8em",
                                color: Colours.primary,
                            }}
                        >
                            <ButtonBase
                                onClick={() => {
                                    navigate("/pdfexport");
                                }}
                                sx={{ fontFamily: "Montserrat" }}
                            >
                                <Icons.ArrowFullDown
                                    sx={{ fontSize: "1em", mr: "0.5em" }}
                                />
                                Generate PDF
                            </ButtonBase>{" "}
                        </Box>
                    </Stack>
                )}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={1}
                >
                    {/*  ____    _  _____  _  _______   ______  _____  */}
                    {/* |  _ \  / \|_   _|/ \|_   _\ \ / /  _ \| ____| */}
                    {/* | | | |/ _ \ | | / _ \ | |  \ V /| |_) |  _|   */}
                    {/* | |_| / ___ \| |/ ___ \| |   | | |  __/| |___  */}
                    {/* |____/_/   \_\_/_/   \_\_|   |_| |_|   |_____| */}
                    {!hide.includes("DATATYPE") && (
                        <ToggleButtonTitled
                            values={{
                                [DATATYPE_RESDATE]: "Booked on date",
                                [DATATYPE_CHECKIN]: "Checked in date",
                                [DATATYPE_CHECKOUT]: "Checked out date",
                            }}
                            valueSet={js.dataType ?? DATATYPE_RESDATE}
                            onChange={(e, v) => {
                                dispatch(setJS({ ...js, dataType: v }));
                            }}
                        />
                    )}

                    {/*  _____ ___ __  __ _____   ____  _____ ____  ___ ___  ____   */}
                    {/* |_   _|_ _|  \/  | ____| |  _ \| ____|  _ \|_ _/ _ \|  _ \  */}
                    {/*   | |  | || |\/| |  _|   | |_) |  _| | |_) || | | | | | | | */}
                    {/*   | |  | || |  | | |___  |  __/| |___|  _ < | | |_| | |_| | */}
                    {/*   |_| |___|_|  |_|_____| |_|   |_____|_| \_\___\___/|____/  */}
                    {!hide.includes("TIME_PERIOD") && (
                        <Grid item>
                            <Typography>Time period</Typography>
                            <FormControl size="small">
                                <Select
                                    labelId="timePeriodLabel"
                                    id="timePeriodSelect"
                                    value={js.presetDate ?? PRESET_CUSTOM}
                                    onChange={(e, v) =>
                                        dispatch(
                                            setJS(
                                                handlePresetChange(
                                                    v.props.value
                                                )
                                            )
                                        )
                                    }
                                    sx={{ height: ITEM_HEIGHT }}
                                    MenuProps={{ sx: { maxHeight: "280px" } }}
                                >
                                    {PRESET_GROUP.map((e) => (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={PRESET_CUSTOM}>
                                        Custom
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/*  _____ ___ __  __ _____   ____      _    _   _  ____ _____  */}
                    {/* |_   _|_ _|  \/  | ____| |  _ \    / \  | \ | |/ ___| ____| */}
                    {/*   | |  | || |\/| |  _|   | |_) |  / _ \ |  \| | |  _|  _|   */}
                    {/*   | |  | || |  | | |___  |  _ <  / ___ \| |\  | |_| | |___  */}
                    {/*   |_| |___|_|  |_|_____| |_| \_\/_/   \_\_| \_|\____|_____| */}
                    {!hide.includes("TIME_RANGE") && (
                        <Grid item>
                            <Typography>Stay date</Typography>

                            {js.presetDate !== PRESET_CHOOSE_MONTH ? (
                                <DateRangePicker
                                    boxProps={{ sx: { height: ITEM_HEIGHT } }}
                                    fromDateProps={{
                                        value: new Date(js.fromDay),
                                    }}
                                    onChangeFromDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                fromDay: formatDate(newValue),
                                                presetDate: PRESET_CUSTOM,
                                                // If selected period, update arrival
                                                fromArrivalDay:
                                                    js.presetArrivalDate ===
                                                    PRESET_ARRIVAL_SELECTED_PERIOD
                                                        ? formatDate(newValue)
                                                        : js.fromArrivalDay,
                                            })
                                        );
                                    }}
                                    toDateProps={{ value: new Date(js.toDay) }}
                                    onChangeToDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                toDay: formatDate(newValue),
                                                presetDate: PRESET_CUSTOM,
                                                // If selected period, update arrival
                                                toArrivalDay:
                                                    js.presetArrivalDate ===
                                                    PRESET_ARRIVAL_SELECTED_PERIOD
                                                        ? formatDate(newValue)
                                                        : js.toArrivalDay,
                                            })
                                        );
                                    }}
                                    open={openDatePicker}
                                />
                            ) : (
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enIE}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            height: ITEM_HEIGHT,
                                            maxWidth: 200,
                                            border: 1,
                                            borderRadius: 1,
                                            borderColor: Colours.midLightText,
                                            "& .MuiTextField-root": {
                                                border: "none",
                                                "& .MuiOutlinedInput-input": {
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    paddingRight: 0,
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    width: "100%",
                                                },
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    >
                                        <DatePicker
                                            open={openDatePicker}
                                            onClose={() =>
                                                setOpenDatePicker(false)
                                            }
                                            inputFormat="MMM yyyy"
                                            views={["year", "month"]}
                                            openTo="month"
                                            renderInput={(params) => (
                                                <div
                                                    onClick={() =>
                                                        setOpenDatePicker(true)
                                                    }
                                                >
                                                    <TextField {...params} />
                                                </div>
                                            )}
                                            value={
                                                new Date(js.fromDay) ??
                                                new Date()
                                            }
                                            minDate={
                                                new Date(
                                                    new Date().getUTCFullYear() -
                                                        getYearsBefore()
                                                            .length +
                                                        3,
                                                    0,
                                                    1
                                                )
                                            }
                                            maxDate={
                                                new Date(
                                                    new Date().getFullYear() +
                                                        3,
                                                    12,
                                                    31
                                                )
                                            }
                                            onChange={(newValue) => {
                                                let year =
                                                    newValue.getFullYear();
                                                let month = newValue.getMonth();
                                                setAuxPresetDate({
                                                    year,
                                                    month,
                                                });
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            )}
                        </Grid>
                    )}

                    {/*     _    ____  ____  _____     ___    _       ____  _____ ____  ___ ___  ____   */}
                    {/*    / \  |  _ \|  _ \|_ _\ \   / / \  | |     |  _ \| ____|  _ \|_ _/ _ \|  _ \  */}
                    {/*   / _ \ | |_) | |_) || | \ \ / / _ \ | |     | |_) |  _| | |_) || | | | | | | | */}
                    {/*  / ___ \|  _ <|  _ < | |  \ V / ___ \| |___  |  __/| |___|  _ < | | |_| | |_| | */}
                    {/* /_/   \_\_| \_\_| \_\___|  \_/_/   \_\_____| |_|   |_____|_| \_\___\___/|____/  */}
                    {!hide.includes("ARRIVAL_PERIOD") && (
                        <Grid item>
                            <Typography>Arrival period</Typography>
                            <FormControl size="small">
                                <Select
                                    labelId="timePeriodLabel"
                                    id="arrivalPeriodSelect"
                                    value={
                                        js.presetArrivalDate ??
                                        PRESET_ARRIVAL_CUSTOM
                                    }
                                    onChange={(e, v) =>
                                        dispatch(
                                            setJS(
                                                handlePresetArrivalChange(
                                                    v.props.value
                                                )
                                            )
                                        )
                                    }
                                    sx={{ height: ITEM_HEIGHT }}
                                    MenuProps={{ sx: { maxHeight: "280px" } }}
                                >
                                    {PRESET_ARRIVAL_GROUP.map((e) => (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={PRESET_ARRIVAL_CUSTOM}>
                                        Custom
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/*  _____ ___ __  __ _____      _    ____  ____  _____     ___    _      */}
                    {/* |_   _|_ _|  \/  | ____|    / \  |  _ \|  _ \|_ _\ \   / / \  | |     */}
                    {/*   | |  | || |\/| |  _|     / _ \ | |_) | |_) || | \ \ / / _ \ | |     */}
                    {/*   | |  | || |  | | |___   / ___ \|  _ <|  _ < | |  \ V / ___ \| |___  */}
                    {/*   |_| |___|_|  |_|_____| /_/   \_\_| \_\_| \_\___|  \_/_/   \_\_____| */}
                    {!hide.includes("TIME_ARRIVAL_RANGE") && (
                        <Grid item>
                            <Typography>Custom arrival date</Typography>

                            {js.presetArrivalDate !==
                            PRESET_ARRIVAL_CHOOSE_MONTH ? ( // TODO: finish this one
                                <DateRangePicker
                                    boxProps={{ sx: { height: ITEM_HEIGHT } }}
                                    fromDateProps={{
                                        value: new Date(js.fromArrivalDay),
                                    }}
                                    onChangeFromDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                fromArrivalDay:
                                                    formatDate(newValue),
                                                presetArrivalDate:
                                                    PRESET_ARRIVAL_CUSTOM,
                                            })
                                        );
                                    }}
                                    toDateProps={{
                                        value: new Date(js.toArrivalDay),
                                    }}
                                    onChangeToDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                toArrivalDay:
                                                    formatDate(newValue),
                                                presetArrivalDate:
                                                    PRESET_ARRIVAL_CUSTOM,
                                            })
                                        );
                                    }}
                                    open={openArrivalDatePicker}
                                />
                            ) : (
                                //    ___ _                       __  __         _   _
                                //   / __| |_  ___  ___ ___ ___  |  \/  |___ _ _| |_| |_
                                //  | (__| ' \/ _ \/ _ (_-</ -_) | |\/| / _ \ ' \  _| ' \
                                //   \___|_||_\___/\___/__/\___| |_|  |_\___/_||_\__|_||_|
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enIE}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            height: ITEM_HEIGHT,
                                            maxWidth: 200,
                                            border: 1,
                                            borderRadius: 1,
                                            borderColor: Colours.midLightText,
                                            "& .MuiTextField-root": {
                                                border: "none",
                                                "& .MuiOutlinedInput-input": {
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    paddingRight: 0,
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    width: "100%",
                                                },
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    >
                                        <DatePicker
                                            open={openArrivalDatePicker}
                                            onClose={() =>
                                                setOpenArrivalDatePicker(false)
                                            }
                                            inputFormat="MMM yyyy"
                                            views={["year", "month"]}
                                            openTo="month"
                                            renderInput={(params) => (
                                                <div
                                                    onClick={() =>
                                                        setOpenArrivalDatePicker(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <TextField {...params} />
                                                </div>
                                            )}
                                            value={
                                                new Date(js.fromArrivalDay) ??
                                                new Date()
                                            }
                                            minDate={MIN_DATE}
                                            maxDate={MAX_DATE}
                                            onChange={(newValue) => {
                                                let year =
                                                    newValue.getFullYear();
                                                let month = newValue.getMonth();
                                                setAuxPresetArrivalDate({
                                                    year,
                                                    month,
                                                });
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            )}
                        </Grid>
                    )}

                    {/*  _____ ___ _   _____ _____ ____  ____   */}
                    {/* |  ___|_ _| | |_   _| ____|  _ \/ ___|  */}
                    {/* | |_   | || |   | | |  _| | |_) \___ \  */}
                    {/* |  _|  | || |___| | | |___|  _ < ___) | */}
                    {/* |_|   |___|_____|_| |_____|_| \_\____/  */}
                    {!hide.includes("FILTERS") && (
                        <Grid item>
                            <FiltersByType hide={hide} height={ITEM_HEIGHT} />
                        </Grid>
                    )}

                    {/*    ____                     _ _       _   _                    ___        _          */}
                    {/*   / ___|__ _ _ __   ___ ___| | | __ _| |_(_) ___  _ __  ___   / _ \ _ __ | |_   _    */}
                    {/*  | |   / _` | '_ \ / __/ _ \ | |/ _` | __| |/ _ \| '_ \/ __| | | | | '_ \| | | | |   */}
                    {/*  | |__| (_| | | | | (_|  __/ | | (_| | |_| | (_) | | | \__ \ | |_| | | | | | |_| |   */}
                    {/*   \____\__,_|_| |_|\___\___|_|_|\__,_|\__|_|\___/|_| |_|___/  \___/|_| |_|_|\__, |   */}
                    {/*                                                                             |___/    */}
                    {!hide.includes("CANCELLATIONS") && (
                        <Grid item>
                            <Typography>Cancellations</Typography>
                            <FormControl>
                                <Select
                                    labelId="cancellationsFilter"
                                    id="cancellationsFilter"
                                    value={js.filters.Cancellations}
                                    sx={{
                                        height: ITEM_HEIGHT,
                                        width: "140px",
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        dispatch(
                                            setJS({
                                                ...js,
                                                filters: {
                                                    ...(js.filters ?? {}),
                                                    Cancellations: value,
                                                },
                                            })
                                        );
                                    }}
                                    size="small"
                                >
                                    {Object.keys(CANCELLATIONS_FILTER).map(
                                        (key) => {
                                            return hide.includes(
                                                CANCELLATIONS_FILTER[key]
                                                    .hideKey
                                            ) ? (
                                                <></>
                                            ) : (
                                                <MenuItem
                                                    key={key + "key"}
                                                    value={key}
                                                >
                                                    {
                                                        CANCELLATIONS_FILTER[
                                                            key
                                                        ].label
                                                    }
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/*   _____                                 _                  */}
                    {/*  / ____|     _ _  __   _ __   __ _ _ __(_)___  ___  _ __   */}
                    {/* | |    / _ \| '_ ` _ \| '_ \ / _` | '__| / __|/ _ \| '_ \  */}
                    {/* | |___| (_) | | | | | | |_) | (_| | |  | \__ \ (_) | | | | */}
                    {/*  \_____\___/|_| |_| |_| .__/ \__,_|_|  |_|___/\___/|_| |_| */}
                    {/*                       |_|                                  */}
                    {!hide.includes("COMPARISON") && (
                        <Grid item>
                            <Typography>Comparison</Typography>

                            <FormControl>
                                <Select
                                    labelId="comparisonFilter"
                                    id="comparisonFilter"
                                    value={
                                        js.filters.Comparison ??
                                        DEFAULT_COMPARISON
                                    }
                                    sx={{
                                        height: ITEM_HEIGHT,
                                        width: "140px",
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        dispatch(
                                            setJS({
                                                ...js,
                                                filters: {
                                                    ...(js.filters ?? {}),
                                                    Comparison: value,
                                                },
                                            })
                                        );
                                    }}
                                    size="small"
                                >
                                    {Object.keys(COMPARISON_FILTER).map(
                                        (key) => {
                                            return (
                                                <MenuItem
                                                    key={key + "key"}
                                                    value={key}
                                                >
                                                    {
                                                        COMPARISON_FILTER[key]
                                                            .label
                                                    }
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/*  ____  _____ _____ ____  _____ ____  _   _  */}
                    {/* |  _ \| ____|  ___|  _ \| ____/ ___|| | | | */}
                    {/* | |_) |  _| | |_  | |_) |  _| \___ \| |_| | */}
                    {/* |  _ <| |___|  _| |  _ <| |___ ___) |  _  | */}
                    {/* |_| \_\_____|_|   |_| \_\_____|____/|_| |_| */}
                    {/* <Grid item>
                        <RefreshButton ITEM_HEIGHT={ITEM_HEIGHT} />
                    </Grid> */}

                    {/*   ____ ____   ___  _   _ ____    ______   __ */}
                    {/*  / ___|  _ \ / _ \| | | |  _ \  | __ ) \ / / */}
                    {/* | |  _| |_) | | | | | | | |_) | |  _ \\ V /  */}
                    {/* | |_| |  _ <| |_| | |_| |  __/  | |_) || |   */}
                    {/*  \____|_| \_\\___/ \___/|_|     |____/ |_|   */}
                    <CheckShow rolesThatCanSee={ARO_ADMIN}>
                        <Grid item>
                            <Typography> Group by</Typography>
                            <FormControl>
                                <Select
                                    sx={{ height: ITEM_HEIGHT }}
                                    multiple
                                    value={js.groupBy ?? []}
                                    onChange={(e, v) => {
                                        let value = v.props.value;

                                        let index = (js.groupBy ?? []).indexOf(
                                            value
                                        );

                                        if (index > -1)
                                            dispatch(
                                                setJS({
                                                    ...js,
                                                    groupBy: (
                                                        js.groupBy ?? []
                                                    ).filter(
                                                        (item) => item !== value
                                                    ),
                                                })
                                            );
                                        else
                                            dispatch(
                                                setJS({
                                                    ...js,
                                                    groupBy: [
                                                        ...(js.groupBy ?? []),
                                                        value,
                                                    ],
                                                })
                                            );
                                    }}
                                    // input={<OutlinedInput label="Group By" />}
                                    renderValue={() => "Group by.."}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                // maxHeight: 60 * 4.5 + 8,
                                                width: "auto",
                                            },
                                        },
                                    }}
                                >
                                    {GROUPs.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox
                                                checked={
                                                    (js.groupBy ?? []).indexOf(
                                                        name
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </CheckShow>
                    <CheckShow rolesThatCanSee={ARO_ADMIN}>
                        <Grid item>
                            <Box
                                sx={{
                                    height: ITEM_HEIGHT,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Tooltip title="Number of entries under the selected filters">
                                    <Typography>{lenProcessed}</Typography>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </CheckShow>
                </Grid>
            </Container>

            <Container maxWidth={false}>
                <TextPulledAgo />
            </Container>

            {/*  _____ ___ _   _____ _____ ____  ____    _     ___ _   _ _____  */}
            {/* |  ___|_ _| | |_   _| ____|  _ \/ ___|  | |   |_ _| \ | | ____| */}
            {/* | |_   | || |   | | |  _| | |_) \___ \  | |    | ||  \| |  _|   */}
            {/* |  _|  | || |___| | | |___|  _ < ___) | | |___ | || |\  | |___  */}
            {/* |_|   |___|_____|_| |_____|_| \_\____/  |_____|___|_| \_|_____| */}
            {!hide.includes("FILTERS_LINE") && (
                <Container maxWidth={false} sx={{ pb: 2 }}>
                    <FiltersLine />
                </Container>
            )}

            {/*  ____        _         */}
            {/* |  _ \  __ _| |_ __ _  */}
            {/* | | | |/ _` | __/ _` | */}
            {/* | |_| | (_| | || (_| | */}
            {/* |____/ \__,_|\__\__,_| */}
            {/* <FiltersTableReport data={processedData} />
            <br /> */}
            {/* {GB_NotDate(js).length >= 1 && (
                <>
                    <ChartSelectorFilters
                        type={TYPE_RATE}
                        data={processedData}
                    />
                    <br />
                    <br />
                    <ChartSelectorFilters
                        type={TYPE_LINE}
                        data={processedData}
                    />
                    <br />
                    <br />
                    <ChartSelectorFilters
                        type={TYPE_BAR}
                        data={processedData}
                    />
                </>
            )} */}
            {/* <br /> */}
        </>
    );
};

export default FiltersReport;
