import React from "react";

import { Grid, Typography } from "@mui/material";

import FiltersReport from "../components/FiltersReport";
import FiltersReportsArray, {
    BREAK_LINE,
    CHART_TREND,
    CHART_TREND_YEAR_MONTH,
    fields_FiltersTableReport,
    fields_OverviewTable,
    TABLE_METRICS,
    TABLE_OVERVIEW,
    TABLE_OVERVIEW_YEAR_MONTH,
} from "../components/FiltersReportsArray";
import {
    DATATYPE_CHECKIN,
    DISPLAY_ELEMENT_HELPER,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    GROUP_OFFERS,
    GROUP_ROOM,
    PRESET_NEXT_12_MONTHS,
} from "../helpers/filters";

const REPORTS = [
    // {
    //     type: TABLE_METRICS,
    //     groupBy: [],
    //     dataType: DATATYPE_CHECKIN,

    //     fieldsOrder: [
    //         "bookingsNotCancelled",
    //         "roomNightsTotal",
    //         "revenueRoom",
    //         "ADRTotal",
    //         // "ALoSTotal",
    //         // "ABWTotal",
    //         // "BWTotal",
    //         // "bookingsCancelled",
    //     ],
    //     hideFields: [
    //         ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
    //             (e) =>
    //                 ![
    //                     "bookingsNotCancelled",
    //                     "roomNightsTotal",
    //                     "revenueRoom",
    //                     "ADRTotal",
    //                     // "ALoSTotal",
    //                     // "ABWTotal",
    //                     // "BWTotal",
    //                     // "bookingsCancelled",
    //                 ].includes(e)
    //         ),
    //     ],

    //     PDF: "Metrics",
    // },
    // { type: BREAK_LINE, quantity: 1 },
    {
        type: CHART_TREND_YEAR_MONTH,
        dataType: DATATYPE_CHECKIN,
        title: "Rate Plan - Trend chart - 1",
        groupBy: [
            // GROUP_OFFERS,
            GROUP_DATE_YEAR,
            GROUP_DATE_MONTH,
            GROUP_DATE_DAY,
        ],
        filters: { Types: ["Special Offer"] },
        props: {
            title: "Arrivals chart",
            metricsTitle: "‎ ",
            metrics: {
                bookingsTotal: {
                    ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                    short: "Arrivals",
                },
                roomNightsTotal: { ...DISPLAY_ELEMENT_HELPER.roomNightsTotal },
                revenueRoom: { ...DISPLAY_ELEMENT_HELPER.revenueRoom },
                ADRTotal: { ...DISPLAY_ELEMENT_HELPER.ADRTotal },
            },
            showLastYear: true,
        },
    },
    {
        type: TABLE_OVERVIEW_YEAR_MONTH,
        title: "By Month - Overview table",
        dataType: DATATYPE_CHECKIN,
        groupBy: [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
        ...fields_OverviewTable,
        sortable: [],
        filters: { Types: ["Special Offer"] },
        fieldsOrder: [
            "bookingsRooms",
            // "bookingsTotal",
            "roomNightsTotal",
            "revenueRoom",
            "ADRTotal",
            // "ALoSTotal",
            // "ABWTotal",
            // "BWTotal",
            // "bookingsCancelled",
        ],
        hideFields: [
            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                (e) =>
                    ![
                        "bookingsRooms",
                        // "bookingsTotal",
                        "roomNightsTotal",
                        "revenueRoom",
                        "ADRTotal",
                        // "ALoSTotal",
                        // "ABWTotal",
                        // "BWTotal",
                        // "bookingsCancelled",
                    ].includes(e)
            ),
        ],
    },
];

const ArrivalsScreen = () => {
    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Arrival report</Typography>
                </Grid>
            </Grid>
            <FiltersReport
                defaultValues={{
                    presetDate: PRESET_NEXT_12_MONTHS,
                    filters: {
                        Cancellations: "included",
                        Comparison: "previousYear",
                    },
                }}
                hide={[
                    "DATATYPE",
                    "FILTERS_VOUCHERS",
                    "COMPARISON",
                    "CANCELLATIONS_ONLY",
                ]}
                setProcessed={() => {}}
            />
            <FiltersReportsArray reports={REPORTS} />
        </>
    );
};

export default ArrivalsScreen;
