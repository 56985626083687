import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Checkbox,
    FormControlLabel,
    Grid,
    Skeleton,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";

import {
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    process_,
    GROUP_TYPE,
    GROUP_VOUCHERS,
    DISPLAY_ELEMENT_HELPER,
} from "../helpers/filters";
import Colours from "../helpers/colours";

import { loadOne, removeOne } from "../actions/PDFExport";

import FiltersChartBar from "./FiltersChartBar";
import FiltersTableReport from "./FiltersTableReport";
import FiltersChartBarCategory from "./FiltersChartBarCategory";
import { FiltersLine } from "./FiltersByType";
import OverviewTable from "./OverviewTable";
import GeographicalMarketOverview from "./reports/GeographicalMarketOverview";
import TrendByChart from "./TrendByChart";
import { CheckHideBool } from "./shared/CheckHide";
import WrapperPDFTable from "./shared/WrapperPDFTable";
import OverviewTableYearMonth from "./OverviewTableYearMonth";
import TrendByChartYearMonth from "./TrendByChartYearMonth";
import TableReservations from "./TableReservations";

export const FILTERS_LINE = "FILTER_LINE";
export const BREAK_LINE = "BREAK_LINE";
export const CUSTOM = "CUSTOM";
export const GRID_HORIZONTAL = "GRID_HORIZONTAL"; // xs=1 / sm=2 / md and forward=3
export const TABS = "TABS";
export const TAB = "TAB";

export const CHART_CATEGORY = "CHART_CATEGORY";
export const CHART_LINES = "CHART_LINES";
export const CHART_BARS = "CHART_BARS";
export const CHART_MAP = "CHART_MAP";
export const CHART_TREND = "CHART_TREND";
export const CHART_TREND_YEAR_MONTH = "CHART_TREND_YEAR_MONTH";

export const TABLE_METRICS = "TABLE_METRICS";
export const TABLE_OVERVIEW = "TABLE_OVERVIEW";
export const TABLE_OVERVIEW_YEAR_MONTH = "TABLE_OVERVIEW_YEAR_MONTH";
export const TABLE_RESERVATIONS = "TABLE_RESERVATIONS";

// TODO: Skeleton
// TODO: Divider
// TODO: Historical of processed

// EXAMPLE FILTERS
// filters: {
//     Countries: [],
//     Offers: [],
//     Rooms: [],
//     Types: [],
//     VoucherNames: [],
// },

// TODO: Come back to this implementation

// const fields_ChartMap = {
//     fieldsOrder: ["bookingsNotCancelled", "roomNightsTotal", "revenueRoom"],
//     hideFields: [
//         ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
//             (e) =>
//                 ![
//                     "bookingsNotCancelled",
//                     "roomNightsTotal",
//                     "revenueRoom",
//                 ].includes(e)
//         ),
//     ],
// };

export const fields_Commission = {
    fieldsOrder: [
        "commissionNotCancelled",
        // "commissionCancelled",
        // "commissionTotal",
        "commissionRoomsNotCancelled",
        // "commissionRoomsCancelled",
        // "commissionRoomsTotal",
        "commissionVouchers",
        // "commissionPercentageFromVouchers",
        // "commissionPercentageFromRooms",
    ],
    hideFields: [
        ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
            (e) =>
                ![
                    "commissionNotCancelled",
                    // "commissionCancelled",
                    // "commissionTotal",
                    "commissionRoomsNotCancelled",
                    // "commissionRoomsCancelled",
                    // "commissionRoomsTotal",
                    "commissionVouchers",
                    // "commissionPercentageFromVouchers",
                    // "commissionPercentageFromRooms",
                ].includes(e)
        ),
    ],
};

export const fields_OverviewTable = {
    fieldsOrder: [
        "bookingsNotCancelled",
        "roomNightsTotal",
        "revenueRoom",
        "ADRTotal",
        "ALoSTotal",
        "ABWTotal",
        "BWTotal",
        "bookingsCancelled",
    ],
    hideFields: [
        ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
            (e) =>
                ![
                    "bookingsNotCancelled",
                    "roomNightsTotal",
                    "revenueRoom",
                    "ADRTotal",
                    "ALoSTotal",
                    "ABWTotal",
                    "BWTotal",
                    "bookingsCancelled",
                ].includes(e)
        ),
    ],
};
export const fields_OverviewTable_Vouchers = {
    fieldsOrder: ["vouchersCount", "revenueTotal", "voucherMin", "voucherMax"],
    hideFields: [
        ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
            (e) =>
                ![
                    "vouchersCount",
                    "revenueTotal",
                    "voucherMin",
                    "voucherMax",
                ].includes(e)
        ),
    ],
};

export const fields_FiltersTableReport = {
    fieldsOrder: [
        "revenueTotal",
        "revenueRoom",
        "revenueRoomAndExtras",
        "revenueExtras",
        "bookingsRooms",
        "roomNightsTotal",
        "bookingsCancelled",
        "empty",
        "ABWTotal",
        "ALoSTotal",
        "ADRTotal",
        "empty",
        "revenueVouchers",
        "vouchersBookings",
        "vouchersCount",
    ],
    hideFields: [
        ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
            (e) =>
                ![
                    "revenueTotal",
                    "revenueRoom",
                    "revenueRoomAndExtras",
                    "revenueExtras",
                    "bookingsRooms",
                    "roomNightsTotal",
                    "bookingsCancelled",
                    "revenueVouchers",
                    "ABWTotal",
                    "ALoSTotal",
                    "ADRTotal",
                    "vouchersBookings",
                    "vouchersCount",
                ].includes(e)
        ),
    ],
};

export const REPORTS = [
    {
        type: TABLE_METRICS,
        groupBy: [],
        ...fields_FiltersTableReport,
        PDF: "Metrics",
    },
    { type: BREAK_LINE, quantity: 1 },
    {
        type: TABS,
        default: "Rate Plan",
        tabs: [
            {
                type: TAB,
                title: "Rate Plan",
                reports: [
                    {
                        type: GRID_HORIZONTAL,
                        reports: [
                            {
                                type: CHART_CATEGORY,
                                title: "Rate Plan - Bar chart - 1",
                                groupBy: [GROUP_OFFERS],
                                metric: "bookingsTotal",
                                metrics: {
                                    ...DISPLAY_ELEMENT_HELPER,
                                    bookingsTotal: {
                                        ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                                        short: "Bookings",
                                    },
                                },
                            },
                            // {
                            //     type: CHART_CATEGORY,
                            //     groupBy: [GROUP_OFFERS],
                            //     metric: "revenueTotal",
                            // },
                        ],
                    },

                    {
                        type: CHART_TREND,
                        title: "Rate Plan - Trend chart - 1",
                        groupBy: [
                            GROUP_OFFERS,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        filters: { Types: ["Special Offer"] },
                        props: { title: "Trend by rate plan" },
                    },
                    {
                        type: TABLE_OVERVIEW,
                        title: "Rate Plan - Overview table",
                        groupBy: [GROUP_OFFERS],
                        ...fields_OverviewTable,
                    },
                    // {
                    //     type: TABLE_OVERVIEW,
                    //     groupBy: [GROUP_OFFERS],
                    // },
                ],
            },
            {
                type: TAB,
                title: "Room Type",
                reports: [
                    {
                        type: GRID_HORIZONTAL,
                        reports: [
                            {
                                type: CHART_CATEGORY,
                                title: "Room Type - Bar chart - 1",
                                groupBy: [GROUP_ROOM],
                                metric: "bookingsTotal",
                                metrics: {
                                    ...DISPLAY_ELEMENT_HELPER,
                                    bookingsTotal: {
                                        ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                                        short: "Bookings",
                                    },
                                },
                            },
                            // {
                            //     type: CHART_CATEGORY,
                            //     groupBy: [GROUP_ROOM],
                            //     metric: "revenueTotal",
                            // },
                        ],
                    },

                    {
                        type: CHART_TREND,
                        title: "Room Type - Trend chart - 1",

                        groupBy: [
                            GROUP_ROOM,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        filters: { Types: ["Special Offer"] },
                        props: { title: "Trend by room type" },
                    },
                    {
                        type: TABLE_OVERVIEW,
                        title: "Room Type - Overview table",

                        groupBy: [GROUP_ROOM],
                        ...fields_OverviewTable,
                        sortable: [],
                    },
                    // {
                    //     type: TABLE_OVERVIEW,
                    //     groupBy: [GROUP_ROOM],
                    // },
                ],
            },
            {
                type: TAB,
                title: "Geographical Market",
                reports: [
                    // {
                    //     type: GRID_HORIZONTAL,
                    //     reports: [
                    //         {
                    //             type: CHART_CATEGORY,
                    //             groupBy: [GROUP_COUNTRIES],
                    //             metric: "bookingsTotal",
                    //         },
                    //         {
                    //             type: CHART_CATEGORY,
                    //             groupBy: [GROUP_COUNTRIES],
                    //             metric: "revenueTotal",
                    //         },
                    //     ],
                    // },
                    {
                        type: CHART_MAP,
                        title: "Geographical Market - Map",
                        groupBy: [GROUP_COUNTRIES],
                        // ...fields_ChartMap,
                    },
                    {
                        type: CHART_TREND,
                        title: "Geographical Market - Trend chart - 1",
                        groupBy: [
                            GROUP_COUNTRIES,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        props: { title: "Trend by country" },
                    },
                    {
                        type: TABLE_OVERVIEW,
                        title: "Geographical Market - Overview table",
                        groupBy: [GROUP_COUNTRIES],
                        ...fields_OverviewTable,
                    },
                ],
            },
            {
                type: TAB,
                isVoucher: true,
                title: "Vouchers",
                reports: [
                    {
                        type: GRID_HORIZONTAL,
                        reports: [
                            {
                                type: CHART_CATEGORY,
                                title: "Vouchers - Bar chart - 1",
                                groupBy: [GROUP_VOUCHERS],
                                metric: "vouchersCount",
                                metrics: Object.entries(
                                    DISPLAY_ELEMENT_HELPER
                                ).reduce((acc, [key, value]) => {
                                    acc[key] =
                                        key === "revenueVouchers" ||
                                        key === "vouchersCount"
                                            ? {
                                                  ...value,
                                                  important: true,
                                                  short:
                                                      "revenueVouchers" === key
                                                          ? "Revenue"
                                                          : "Number sold",
                                              }
                                            : {
                                                  ...value,
                                                  important: false,
                                              };
                                    return acc;
                                }, {}),
                                filters: { Types: ["Voucher"] },
                            },
                        ],
                    },
                    {
                        type: CHART_TREND,
                        title: "Vouchers - Trend chart - 1",
                        groupBy: [
                            GROUP_VOUCHERS,
                            GROUP_DATE_YEAR,
                            GROUP_DATE_MONTH,
                            GROUP_DATE_DAY,
                        ],
                        filters: { Types: ["Voucher"] },
                        props: {
                            metrics: {
                                revenueVouchers: {
                                    ...DISPLAY_ELEMENT_HELPER.revenueVouchers,
                                    short: "Revenue",
                                },
                                vouchersCount: {
                                    ...DISPLAY_ELEMENT_HELPER.vouchersCount,
                                    short: "Number sold",
                                },
                            },
                        },
                    },
                    {
                        type: TABLE_OVERVIEW,
                        title: "Vouchers - Overview table",
                        groupBy: [GROUP_VOUCHERS],
                        filters: { Types: ["Voucher"] },
                        ...fields_OverviewTable_Vouchers,
                    },
                    // {
                    //     type: TABLE_OVERVIEW,
                    //     groupBy: [GROUP_TYPE],
                    // },
                    // {
                    //     type: TABLE_OVERVIEW,
                    //     groupBy: [GROUP_VOUCHERS],
                    //     filters: { Types: ["Voucher"] },
                    // },
                ],
            },
        ],
    },

    //   _   _ _   _ _   _ ____  _____ ____
    //  | | | | \ | | | | / ___|| ____|  _ \
    //  | | | |  \| | | | \___ \|  _| | | | |
    //  | |_| | |\  | |_| |___) | |___| |_| |
    //   \___/|_| \_|\___/|____/|_____|____/

    // {
    //     type: FILTERS_LINE,
    // },

    // {
    //     type: CHART_LINES,
    //     groupBy: [
    //         GROUP_COUNTRIES,
    //         GROUP_DATE_YEAR,
    //         GROUP_DATE_MONTH,
    //         GROUP_DATE_DAY,
    //     ],
    // },

    // {
    //     type: CHART_BARS,
    //     groupBy: [
    //         GROUP_COUNTRIES,
    //         GROUP_DATE_YEAR,
    //         GROUP_DATE_MONTH,
    //         GROUP_DATE_DAY,
    //     ],
    // },

    //TODO: Voucher type with "filter" option
];

const FiltersReportsArray = ({ PDF = false, reports = REPORTS }) => {
    const { dataBookings } = useSelector((state) => state);
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const tables = useSelector((state) => state.PDFExport.data);

    const [previousTab, setPreviousTab] = useState("");

    useEffect(() => {
        if (previousTab !== "") {
            const loadedReports = Object.keys(tables);

            loadedReports.forEach((key) => {
                if (key.includes(previousTab)) {
                    dispatch(removeOne(key, hotelID));
                }
            });
        }
    }, [previousTab]);

    const dispatch = useDispatch();

    const [processed, setProcessed] = useState({}); // TODO: Store here processed versions so we avoid recalculate them
    const [out, setout] = useState([]);
    const [update, setUpdate] = useState(1);

    const [loading, setLoading] = useState(true);

    const CalculateData = ({ report }) => {
        //   _   _ ____  ____    _  _____ _____       _ ____
        //  | | | |  _ \|  _ \  / \|_   _| ____|     | / ___|
        //  | | | | |_) | | | |/ _ \ | | |  _|    _  | \___ \
        //  | |_| |  __/| |_| / ___ \| | | |___  | |_| |___) |
        //   \___/|_|   |____/_/   \_\_| |_____|  \___/|____/
        let js_ = {
            ...js,
            dataType: report.dataType ?? js.dataType,
            presetDate: report.presetDate ?? js.presetDate,
            fromDay: report.fromDay ?? js.fromDay,
            toDay: report.ToDay ?? js.toDay,
            groupBy: report.groupBy ?? js.groupBy,
            filters: report.filters
                ? { ...js.filters, ...report.filters }
                : js.filters,
            fieldsOrder: report.fieldsOrder ?? js.fieldsOrder,
            hideFields: report.hideFields ?? js.hideFields,
        };
        const jsKey = JSON.stringify(js_) + dataBookings.status;

        // cached
        if (processed[jsKey]) {
            return processed[jsKey];
        } else {
            let p = process_({
                js: js_,
                dataBookings,
                hotelID,
            });
            const data = p.entries;
            const result = { data, js_ };

            const auxProcessed = (prev) => ({ ...prev, [jsKey]: result });
            setProcessed(auxProcessed);
            return result;
        }
    };

    //   ____  _____ _   _ ____  _____ ____
    //  |  _ \| ____| \ | |  _ \| ____|  _ \
    //  | |_) |  _| |  \| | | | |  _| | |_) |
    //  |  _ <| |___| |\  | |_| | |___|  _ <
    //  |_| \_\_____|_| \_|____/|_____|_| \_\
    const RenderReport = (report, key) => {
        const { data, js_ } = CalculateData({ report });
        if ((report.isVoucher ?? false) && !CheckHideBool("Vouchers", hotelID))
            return;

        switch (report.type) {
            case FILTERS_LINE:
                return <FiltersLine key={key} />; // TODO: review with PDF

            case BREAK_LINE:
                return Array(report.quantity ?? 1)
                    .fill()
                    .map((_, idx) => <br key={`${key}-break-${idx}`} />);

            case GRID_HORIZONTAL:
                let aux_out = [];
                report.reports.forEach((r, index) =>
                    aux_out.push(
                        <Grid
                            item
                            xs
                            sm={report.sm ?? 6}
                            md={report.md ?? 4}
                            style={{ flexGrow: 1, maxWidth: "100%" }}
                            key={`key-${key}-${index}`}
                        >
                            {RenderReport(
                                r,
                                report.reports.length > 1
                                    ? `${key} - ${index}`
                                    : key
                            )}
                        </Grid>
                    )
                );
                return (
                    <Grid container direction="row" spacing={1} key={key}>
                        {aux_out}
                    </Grid>
                );

            case TABS:
                return (
                    <TabsHelper
                        setPreviousTab={setPreviousTab}
                        defaultTab={report.default}
                        tabs={report.tabs.filter(
                            (tab) =>
                                !(
                                    !CheckHideBool("Vouchers", hotelID) &&
                                    (tab.isVoucher ?? false)
                                )
                        )}
                        key_={key}
                    />
                );

            case CHART_CATEGORY:
                dispatch(
                    loadOne(
                        <FiltersChartBarCategory
                            key={key}
                            data={data}
                            initialMetric={report.metric ?? "bookingsTotal"}
                            metrics={report.metrics ?? DISPLAY_ELEMENT_HELPER}
                            groupBy={report.groupBy}
                        />,
                        report.title,
                        hotelID
                    )
                );
                return (
                    <FiltersChartBarCategory
                        key={key}
                        data={data}
                        initialMetric={report.metric ?? "bookingsTotal"}
                        metrics={report.metrics ?? DISPLAY_ELEMENT_HELPER}
                        groupBy={report.groupBy}
                    />
                );

            case CHART_LINES:
                return (
                    <FiltersChartBar
                        key={key}
                        data={data}
                        js={js_}
                        chartType="line"
                    />
                );

            case CHART_BARS:
                return (
                    <FiltersChartBar
                        key={key}
                        data={data}
                        js={js_}
                        chartType="bar"
                    />
                );

            case TABLE_METRICS:
                dispatch(
                    loadOne(
                        <FiltersTableReport
                            key={key}
                            data={data}
                            js={js_}
                            PDF={true}
                        />,
                        "Summary",
                        hotelID,
                        { duplicable: false }
                    )
                );

                return (
                    <FiltersTableReport
                        key={key}
                        data={data}
                        js={js_}
                        PDF={PDF}
                    />
                );

            case TABLE_OVERVIEW:
                dispatch(
                    loadOne(
                        <OverviewTable
                            key={key}
                            js={js_}
                            data={data}
                            PDF={true}
                        />,
                        report.title,
                        hotelID,
                        { duplicable: false }
                    )
                );

                return (
                    <OverviewTable key={key} js={js_} data={data} PDF={PDF} />
                );

            case TABLE_OVERVIEW_YEAR_MONTH:
                dispatch(
                    loadOne(
                        <OverviewTableYearMonth
                            key={key}
                            js={js_}
                            data={data}
                            PDF={true}
                        />,
                        report.title,
                        hotelID,
                        { duplicable: false }
                    )
                );

                return (
                    <OverviewTableYearMonth key={key} js={js_} data={data} />
                );

            case TABLE_RESERVATIONS:
                dispatch(
                    loadOne(
                        <TableReservations
                            key={key}
                            js={js_}
                            data={data}
                            PDF={true}
                        />,
                        report.title,
                        hotelID,
                        { duplicable: false }
                    )
                );

                return <TableReservations key={key} js={js_} data={data} />;

            case CHART_MAP:
                dispatch(
                    loadOne(
                        <GeographicalMarketOverview
                            key={key}
                            js={js_}
                            data={data}
                        />,
                        report.title,
                        hotelID,
                        { duplicable: false }
                    )
                );
                return (
                    <GeographicalMarketOverview
                        key={key}
                        js={js_}
                        data={data}
                    />
                );

            case CHART_TREND:
                dispatch(
                    loadOne(
                        <TrendByChart
                            key={key}
                            js={js_}
                            data={data}
                            {...report.props}
                        />,
                        report.title,
                        hotelID
                    )
                );
                return (
                    <TrendByChart
                        key={key}
                        js={js_}
                        data={data}
                        {...report.props}
                    />
                );
            case CHART_TREND_YEAR_MONTH:
                dispatch(
                    loadOne(
                        <TrendByChartYearMonth
                            key={key}
                            js={js_}
                            data={data}
                            {...report.props}
                        />,
                        report.title,
                        hotelID
                    )
                );
                return (
                    <TrendByChartYearMonth
                        key={key}
                        js={js_}
                        data={data}
                        {...report.props}
                    />
                );
            case CUSTOM:
                const auxReport = report.render({ js: js_, hotelID });
                return auxReport;

            default:
                break;
        }
    };

    //   _____  _    ____ ____
    //  |_   _|/ \  | __ ) ___|
    //    | | / _ \ |  _ \___ \
    //    | |/ ___ \| |_) |__) |
    //    |_/_/   \_\____/____/
    const TabsHelper = ({ getLoadedReports, defaultTab, tabs, key_ }) => {
        const [selected, setSelected] = useState(defaultTab);

        return (
            <>
                <Tabs
                    value={selected}
                    onChange={(e, newV) => {
                        setPreviousTab(selected);
                        setSelected(newV);
                    }}
                    sx={{
                        borderBottom: 1,
                        borderColor: Colours.navbarBottomLine,
                    }}
                >
                    {tabs.map((t) => {
                        if (
                            !CheckHideBool("Vouchers", hotelID) &&
                            (t.isVoucher ?? false)
                        )
                            return;
                        else
                            return (
                                <Tab
                                    label={t.title}
                                    key={t.title}
                                    value={t.title}
                                />
                            );
                    })}
                </Tabs>
                {tabs
                    .filter((t) => t.title === selected)
                    .map((tab) => (
                        <div key={tab.title}>
                            <Stack
                                spacing={1}
                                direction={"column"}
                                key={tab.title}
                            >
                                {tab.reports.map((subReport, subKey) =>
                                    RenderReport(subReport, `${key_}-${subKey}`)
                                )}
                            </Stack>
                        </div>
                    ))}
            </>
        );
    };

    useEffect(() => {
        if (dataBookings.status === "loading") {
            setLoading(true);
            setProcessed({});
        }

        if (
            Object.keys(js).length > 0 &&
            (dataBookings.status === "loaded" ||
                dataBookings.status === "partiallyLoaded")
        ) {
            setLoading(false);
            let aux_out = [];

            reports.forEach((r, index) => {
                aux_out.push(RenderReport(r, index));
            });

            setout(() => aux_out);
            setUpdate(update + 1);
        }
    }, [js, reports, hotelID, dataBookings]);

    //   ____  _  _______ _     _____ _____ ___  _   _
    //  / ___|| |/ / ____| |   | ____|_   _/ _ \| \ | |
    //  \___ \| ' /|  _| | |   |  _|   | || | | |  \| |
    //   ___) | . \| |___| |___| |___  | || |_| | |\  |
    //  |____/|_|\_\_____|_____|_____| |_| \___/|_| \_|
    if (loading) {
        return (
            <Stack spacing={1}>
                <Skeleton variant="rounded" height={230} />
                <Skeleton variant="rounded" height={400} />
                <Skeleton variant="text" height={75} />
                <Skeleton variant="rounded" height={250} />
                <Skeleton variant="text" height={75} />
                <Skeleton variant="rounded" height={500} />
            </Stack>
        );
    }

    if (out.length > 0) {
        return (
            <Stack spacing={1} key={update}>
                {out}
            </Stack>
        );
    }

    return null;
};

export default FiltersReportsArray;
