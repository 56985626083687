import React from "react";

import { Grid, Typography } from "@mui/material";

import FiltersReport from "../components/FiltersReport";
import FiltersReportsArray, {
    TABLE_METRICS,
    TABLE_RESERVATIONS,
} from "../components/FiltersReportsArray";
import DataLoader from "../components/DataLoader";

import { DISPLAY_ELEMENT_HELPER } from "../helpers/filters";

const REPORTS = [
    {
        type: TABLE_RESERVATIONS,
    },
    {
        type: TABLE_METRICS,
        groupBy: [],
        // dataType: DATATYPE_CHECKIN,
        fieldsOrder: [
            "bookingsTotal",
            "roomNightsTotal",
            "revenueRoom",
            "ADRTotal",
            // "ALoSTotal",
            // "ABWTotal",
            // "BWTotal",
            // "bookingsCancelled",
        ],
        hideFields: [
            ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                (e) =>
                    ![
                        "bookingsTotal",
                        "roomNightsTotal",
                        "revenueRoom",
                        "ADRTotal",
                        // "ALoSTotal",
                        // "ABWTotal",
                        // "BWTotal",
                        // "bookingsCancelled",
                    ].includes(e)
            ),
        ],
    },
];

const ReservationsTableScreen = () => {
    return (
        <>
            <DataLoader Reservations />
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Reservations Table</Typography>
                    <Typography variant="">
                        This table shows the reservations under the filters.
                    </Typography>
                </Grid>
            </Grid>
            <FiltersReport
                // defaultValues={{
                //     presetDate: PRESET_NEXT_12_MONTHS,
                //     filters: {
                //         Cancellations: "included",
                //         Comparison: "previousYear",
                //     },
                // }}
                // hide={[
                //     "DATATYPE",
                //     "FILTERS_VOUCHERS",
                //     "COMPARISON",
                //     "CANCELLATIONS_ONLY",
                // ]}
                setProcessed={() => {}}
            />
            <FiltersReportsArray reports={REPORTS} />
        </>
    );
};

export default ReservationsTableScreen;
