import { YEARS_BEFORE } from "../configuration";

export const MIN_DATE = new Date(
    new Date().getUTCFullYear() - YEARS_BEFORE,
    0,
    1
);
export const MAX_DATE = new Date(
    new Date().getUTCFullYear() + YEARS_BEFORE,
    0,
    0
);

export const monthToQuarter = (month) => {
    switch (month) {
        case 1:
        case 2:
        case 3:
            return 1;
        case 4:
        case 5:
        case 6:
            return 2;
        case 7:
        case 8:
        case 9:
            return 3;
        case 10:
        case 11:
        case 12:
            return 4;
        default:
            break;
    }
};

export const quarterToMonths = (quarter) => {
    switch (quarter) {
        case 1:
        case "Q1":
            return [1, 2, 3];
        case 2:
        case "Q2":
            return [4, 5, 6];
        case 3:
        case "Q3":
            return [7, 8, 9];
        case 4:
        case "Q4":
            return [10, 11, 12];
        default:
            break;
    }
};

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const months_abbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const daysBetween = (from, to) =>
    (new Date(to).getTime() - new Date(from).getTime()) / (1000 * 3600 * 24);

export const getYearsBefore = () => {
    let years = [];
    let todayYear = new Date().getFullYear() + 1; // Actual Year + 1
    let auxYear = todayYear;
    while (auxYear >= todayYear - YEARS_BEFORE) {
        years.push(auxYear);
        auxYear -= 1;
    }
    return years;
};

export const xAxisYearMonth = () =>
    getYearsBefore()
        .reverse()
        .map((year) => {
            return months.map((month, index) => {
                return new Date(year, index + 1, 0).toISOString().slice(0, 7);
            });
        })
        .flat(1);

export const newDateUTC = (
    year = new Date().getUTCFullYear(),
    month = new Date().getUTCMonth(),
    day = new Date().getUTCDate()
) => new Date(Date.UTC(year, month, day));

export const dateToUTC = (date, split = false) => {
    const dateTransform = new Date(date);
    if (dateTransform.getTime()) {
        // It's a valid date
        const year = dateTransform.getUTCFullYear();
        const month = dateTransform.getUTCMonth();
        const day = dateTransform.getUTCDate();
        return split
            ? { year, month, day }
            : new Date(Date.UTC(year, month, day));
    } else {
        // console.error("dateToUTC failed. Invalid date received: ", date);
        return -1;
    }
};

/**
 * Given a date, returns the date on which the week starts.
 *  arg [string(yyyy-mm-dd)|new Date()] date: date on which the week start is calculated
 *  arg [int] startDay: Day considered the first day of the week, like "Sunday = 0, ... ,Saturday = 6"
 *  return new Date(): date considered the start of the week
 */
export const startOfWeekDate = (date, startDay = 1) => {
    date = new Date(date);
    if (startDay >= 0 && startDay <= 7 && date.getTime()) {
        const dayOfMonth = date.getUTCDate();
        const dayOfWeek = date.getUTCDay();
        const differenceInDays =
            dayOfWeek >= startDay
                ? dayOfWeek - startDay
                : dayOfWeek - startDay + 7;
        date.setUTCDate(dayOfMonth - differenceInDays);
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        return date;
    } else if (startDay < 0 || startDay > 7) {
        console.error(
            "startOfWeekDate failed. Start of day must be >= 0 AND <= 7: ",
            startDay
        );
        return -1;
    } else if (!date.getTime()) {
        console.error("startOfWeekDate failed. Invalid date received: ", date);
        return -1;
    } else {
        console.error("startOfWeekDate failed. Unknown error: ", {
            date,
            startDay,
        });
        return -1;
    }
};
