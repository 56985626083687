import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    AppBar,
    Avatar,
    Box,
    Button,
    Collapse,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";

import CheckShow from "./shared/CheckShow";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { AROLOGO, ARO_ADMIN, ARO_ALL, HOTELS_INFO } from "../configuration";

import { setHotelID } from "../actions/hotelID";
import { cleanJS } from "../actions/globalReportFilters";
import { swapToHotelView, swapToOriginal } from "../actions/authRoles";
import { toggleButtonAction as printButton } from "../actions/PDFExport";

const IMAGE_MAX_SIZE = 30;

const HotelSelector = ({}) => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { user } = useSelector((state) => state.auth);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const [searchText, setSearchText] = useState("");

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        console.log(filteredOptions);
    }, [filteredOptions]);

    useEffect(() => {
        if (user && user.rolesHotels) {
            if ((user.rolesHotels ?? []).includes(-1)) {
                // Remove key 14
                const filteredHotels = Object.entries(HOTELS_INFO)
                    .filter(([key, value]) => key !== "14")
                    .reduce((obj, [key, value]) => {
                        obj[key] = value;
                        return obj;
                    }, {});

                setOptions(Object.values(filteredHotels));
            } else {
                setOptions(
                    user.rolesHotels
                        .filter((h) => h in HOTELS_INFO)
                        .map((id) => HOTELS_INFO[id])
                );
            }
        } else {
            setOptions([]);
        }
    }, [user]);

    useEffect(() => {
        if (searchText === "") {
            setFilteredOptions(options);
        } else {
            const lowerCaseSearchText = searchText.toLowerCase();

            const results = options.filter(
                (option) =>
                    option.name.toLowerCase().includes(lowerCaseSearchText) ||
                    (option.simplified_name ?? "")
                        .toLowerCase()
                        .includes(lowerCaseSearchText) ||
                    option.id
                        .toString()
                        .toLowerCase()
                        .includes(lowerCaseSearchText)
            );
            setFilteredOptions(results);
        }
    }, [options, searchText]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSearchText("");
        setFilteredOptions(options);
    };

    const ListItemComponent = ({ data }) => {
        const [expandChild, setExpandChild] = useState(true);

        return (
            <>
                {!data.hasOwnProperty("isChild") && (
                    <ListItem disableGutters key={data.id}>
                        <ListItemButton
                            onClick={() => {
                                dispatch(setHotelID(data.id));
                                dispatch(cleanJS());
                                handleCloseDialog();
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Hotel Logo ${data.name}`}
                                    src={data.img}
                                />
                            </ListItemAvatar>
                            <CheckShow
                                rolesThatCanSee={ARO_ADMIN}
                                alternative={
                                    <ListItemText
                                        primary={
                                            isMobile
                                                ? data.name.substring(0, 20) +
                                                  "..."
                                                : data.name
                                        }
                                    />
                                }
                            >
                                <ListItemText
                                    secondary={data.id}
                                    primary={
                                        isMobile
                                            ? data.name.substring(0, 20) + "..."
                                            : data.name
                                    }
                                />
                            </CheckShow>
                        </ListItemButton>
                        {data.hasOwnProperty("parent") && (
                            <Button
                                onClick={() => {
                                    setExpandChild(!expandChild);
                                }}
                                sx={{ color: Colours.transparentBubble }}
                            >
                                {expandChild ? (
                                    <Icons.ArrowDown />
                                ) : (
                                    <Icons.ArrowUp />
                                )}
                            </Button>
                        )}
                    </ListItem>
                )}

                {data.hasOwnProperty("parent") && (
                    <Collapse in={expandChild} timeout="auto" unmountOnExit>
                        <List>
                            {data.parent
                                .filter(
                                    (id) =>
                                        user.rolesHotels.includes(-1) ||
                                        user.rolesHotels.includes(id)
                                )
                                .map((id) => HOTELS_INFO[id])
                                .map((subHotel) => (
                                    <ListItemButton
                                        sx={{ py: 1, pl: "2em" }}
                                        onClick={() => {
                                            dispatch(setHotelID(subHotel.id));
                                            dispatch(cleanJS());
                                            handleCloseDialog();
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`Hotel Logo ${subHotel.name}`}
                                                src={subHotel.img ?? ""}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            secondary={subHotel.id}
                                            primary={
                                                isMobile
                                                    ? subHotel.name.substring(
                                                          0,
                                                          20
                                                      ) + "..."
                                                    : subHotel.name
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    };

    return (
        <>
            <Button
                variant="text"
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: Colours.hoverGrey,
                    },
                }}
                onClick={() => setOpenDialog(true)}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {/* <Avatar
                        alt={`Hotel Logo ${HOTELS_INFO[hotelID].name}`}
                        src={HOTELS_INFO[hotelID].img}
                        sx={{ mr: 1 }}
                    /> */}
                    <Box height={"2em"} sx={{ pr: 1, overflow: "hidden" }}>
                        <img
                            src={(HOTELS_INFO[hotelID] ?? {}).img ?? ""}
                            alt={`Hotel Logo ${
                                (HOTELS_INFO[hotelID] ?? {}).name ?? ""
                            }`}
                            style={{
                                height: "100%",
                                width: "auto",
                                maxWidth: "100%",
                            }}
                        />
                    </Box>
                    <Typography>
                        {isMobile
                            ? (
                                  (HOTELS_INFO[hotelID] ?? {}).name ?? ""
                              ).substring(0, 20) + "..."
                            : (HOTELS_INFO[hotelID] ?? {}).name ?? ""}
                    </Typography>
                    <Icons.ArrowDown />
                </Stack>
            </Button>
            <Dialog onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h6">Select Hotel</Typography>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icons.Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) =>
                                setSearchText(event.target.value)
                            }
                            variant="standard"
                        />
                        <IconButton onClick={handleCloseDialog}>
                            <Icons.CloseNavbar />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <List sx={{ pt: 0 }}>
                        {/* No searching, so all */}
                        {searchText === "" &&
                            filteredOptions
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((data, index) => (
                                    <ListItemComponent
                                        key={data.id}
                                        data={data}
                                    />
                                ))}

                        {/* No results */}
                        {filteredOptions.length === 0 && (
                            <React.Fragment>
                                <Divider
                                    sx={{
                                        borderColor: Colours.navbarBottomLine,
                                    }}
                                />
                                <Typography fontSize={"0.8em"}>
                                    No results
                                </Typography>
                            </React.Fragment>
                        )}

                        {/* Top 4 */}
                        {searchText !== "" && filteredOptions.length > 0 && (
                            <>
                                <React.Fragment>
                                    <Divider
                                        sx={{
                                            borderColor:
                                                Colours.navbarBottomLine,
                                        }}
                                    />
                                    <Typography fontSize={"0.8em"}>
                                        Main results
                                    </Typography>
                                </React.Fragment>
                                {filteredOptions
                                    .slice(0, 4)
                                    .map((data, index) => (
                                        <ListItemComponent
                                            key={data.id}
                                            data={data}
                                        />
                                    ))}
                            </>
                        )}

                        {/* Others than top 4 */}
                        {searchText !== "" && filteredOptions.length > 4 && (
                            <>
                                <React.Fragment>
                                    <Divider
                                        sx={{
                                            borderColor:
                                                Colours.navbarBottomLine,
                                        }}
                                    />
                                    <Typography fontSize={"0.8em"}>
                                        Similar results
                                    </Typography>
                                </React.Fragment>
                                {filteredOptions.slice(4).map((data, index) => (
                                    <ListItemComponent
                                        key={data.id}
                                        data={data}
                                    />
                                ))}
                            </>
                        )}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
};

const AdminHotelSelector = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const view = isMobile ? "" : " view";

    const [mode, setMode] = useState("Admin");

    return (
        <Button
            sx={{
                textTransform: "none",
                "&:hover": {
                    backgroundColor: Colours.hoverGrey,
                },
            }}
            onClick={() => {
                if (mode === "Admin") {
                    setMode("Hotel");
                    dispatch(swapToHotelView());
                } else {
                    setMode("Admin");
                    dispatch(swapToOriginal());
                }
            }}
        >
            <Typography>
                {mode} {view}
            </Typography>
        </Button>
    );
};

const handleLogOut = () => {
    localStorage.clear();
    window.location.reload(false);
};

const handlePrintButton = (dispatch) => {
    dispatch(printButton());
};

const OptionsButtons = () => {
    const dispatch = useDispatch();
    const toggleButton = useSelector((state) => state.PDFExport.toggleButton);

    const navigate = useNavigate();
    const location = useLocation();

    const [openPopoverLanguage, setOpenPopoverLanguage] = useState(false);
    const { t, i18n } = useTranslation("navbar");
    const handleLanguageButton = () => {
        setOpenPopoverLanguage(!openPopoverLanguage);
    };

    // TODO: Remove if we wont implement those functionalities
    const OPTIONS = [
        {
            name: "Print", // Not working on dashboard
            action: () => {
                handlePrintButton(dispatch);
            },
            active: toggleButton,
            Icon: Icons.Print,
        },

        {
            name: "PDF Export", // Not working on dashboard
            action: () => navigate("/pdfexport"),
            Icon: Icons.OpenLink,
            active: location.pathname.includes("/pdfexport"),
        },

        {
            name: "Language", // Not fully working
            action: handleLanguageButton,
            active: openPopoverLanguage,
            Icon: Icons.Language,
        },
        { name: "Log Out", action: handleLogOut, Icon: Icons.LogOut },
    ];
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    // const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        // setAnchorEl(null);
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Log Out">
                <IconButton aria-label="log out" onClick={handleLogOut}>
                    <Icons.LogOut />
                </IconButton>
            </Tooltip>
            {/* <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Icons.Options />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {OPTIONS.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleClose();
                            option.action();
                        }}
                    >
                        <option.Icon
                            color={option.active ?? false ? "primary" : ""}
                            sx={{ marginRight: 1 }}
                        />
                        {option.name}
                    </MenuItem>
                ))}
            </Menu> */}

            {/*  _        _    _   _  ____  */}
            {/* | |      / \  | \ | |/ ___| */}
            {/* | |     / _ \ |  \| | |  _  */}
            {/* | |___ / ___ \| |\  | |_| | */}
            {/* |_____/_/   \_\_| \_|\____| */}
            {/* <Popover
                id="popoverLang"
                open={openPopoverLanguage}
                anchorEl={anchorEl}
                onClose={() => setOpenPopoverLanguage(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Container sx={{ m: 2 }}>
                    <Typography variant="h6">{t("language")}</Typography>
                    <RadioGroup
                        value={i18n.language}
                        onChange={(event) => {
                            i18n.changeLanguage(event.target.value);
                            setOpenPopoverLanguage(false);
                        }}
                    >
                        <FormControlLabel
                            value="en"
                            control={<Radio />}
                            label="🇬🇧 English"
                        />
                        <FormControlLabel
                            value="es"
                            control={<Radio />}
                            label="🇪🇸 Español"
                        />
                    </RadioGroup>
                </Container>
            </Popover> */}
        </>
    );
};

const TopBar = ({ actualPage, title }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <AppBar
            position="fixed"
            style={{
                background: "#fafafa", //   "transparent"
                boxShadow: "none",
                borderBottom: `1px solid ${Colours.navbarBottomLine}`,
                zIndex: 1200,
            }}
        >
            <Toolbar>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap" // Asegura que los ítems no se envuelvan
                    sx={{
                        overflowX: "auto", // Permite desplazamiento horizontal si los ítems no caben
                    }}
                >
                    <Grid item>
                        <Link
                            to="/app"
                            underline="none"
                            style={{
                                textDecoration: "none",
                                color: Colours.textColour,
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: {
                                        xs: IMAGE_MAX_SIZE,
                                        md: IMAGE_MAX_SIZE,
                                    },
                                }}
                                alt="Aró Analytics BETA Logo"
                                src={isMobile ? "/aab-favicon.svg" : AROLOGO}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <HotelSelector />
                    </Grid>
                    <CheckShow rolesThatCanSee={ARO_ALL} useEditedRoles={false}>
                        <Grid item>
                            <AdminHotelSelector />
                        </Grid>
                    </CheckShow>
                    <Grid item>
                        <OptionsButtons />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
