import { HOTELS_INFO } from "../configuration";
import { dateToUTC, newDateUTC, daysBetween, months } from "../helpers/dates";

export const TOP_ALL = "All";
export const SliceTop = (arr, top) => {
    return top === TOP_ALL ? arr : arr.slice(0, top);
};

export const round = (val, n = 2) =>
    isNaN(val) ? val : parseFloat((val ?? 0).toFixed(n));

export const processByAttribute = (
    data,
    attribute = "Country",
    cancellations
) => {
    let returnData = {};
    if (data.length > 0) {
        // Get first and last day in data list (not cancelled), usually, 1st Jan and 31st Dec
        let firstDay = data[0].CheckOut;
        let lastDay = data[0].CheckOut;
        data.forEach((element) => {
            if (element.CancelStatus === 0) {
                if (element.CheckOut > lastDay) lastDay = element.CheckOut;
                if (element.CheckOut < firstDay) firstDay = element.CheckOut;
            }
        });

        // const notCancelled = data.filter(
        //     (element) => element.CancelStatus === 0
        // );

        let daysBetweenResAndCheckIn = {};
        let roomsSold = {};

        data.filter((element) =>
            cancellations
                ? element.CancelStatus === 1
                : element.CancelStatus === 0
        ).forEach((element) => {
            // const selectedAttribute = element[`${attribute}`];

            const selectedAttribute =
                attribute === "ResDate"
                    ? `${months[
                          new Date(element[attribute]).getUTCMonth()
                      ].slice(0, 3)} ${new Date(
                          element[attribute]
                      ).getUTCDate()}`
                    : element[`${attribute}`];

            if (selectedAttribute) {
                returnData[selectedAttribute] =
                    returnData[selectedAttribute] || {};
                returnData[selectedAttribute].bookings
                    ? (returnData[selectedAttribute].bookings += 1)
                    : (returnData[selectedAttribute].bookings = 1);
                returnData[selectedAttribute].roomNights
                    ? (returnData[selectedAttribute].roomNights +=
                          element.RoomNights)
                    : (returnData[selectedAttribute].roomNights =
                          element.RoomNights);
                returnData[selectedAttribute].revenue
                    ? (returnData[selectedAttribute].revenue +=
                          element.TotalPrice)
                    : (returnData[selectedAttribute].revenue =
                          element.TotalPrice);

                const bookingWindow = daysBetween(
                    new Date(element.ResDate),
                    new Date(element.CheckIn)
                );
                daysBetweenResAndCheckIn[selectedAttribute]
                    ? daysBetweenResAndCheckIn[selectedAttribute].push(
                          bookingWindow
                      )
                    : (daysBetweenResAndCheckIn[selectedAttribute] = [
                          bookingWindow,
                      ]);

                roomsSold[selectedAttribute]
                    ? (roomsSold[selectedAttribute] += element.NumberRooms)
                    : (roomsSold[selectedAttribute] = element.NumberRooms);
            }
        });

        Object.entries(returnData).forEach(([selectedAttribute, data]) => {
            data.ABV =
                data.revenue && data.bookings
                    ? round(data.revenue / data.bookings, 0)
                    : 0;

            // TODO: review this is okay, check reports.js for inspiration
            data.ADR =
                data.revenue && data.roomNights
                    ? round(data.revenue / data.roomNights, 0)
                    : 0;

            data.ALoS =
                data.roomNights && data.bookings
                    ? round(data.roomNights / data.bookings, 2)
                    : 0;

            data.ABW = round(
                daysBetweenResAndCheckIn[selectedAttribute].reduce(
                    (p, c) => p + c ?? 0,
                    0
                ) / daysBetweenResAndCheckIn[selectedAttribute].length,
                0
            );
            data.revenue = round(data.revenue, 0);
        });
    }

    return returnData;
};

export const process = (data, hotelID) => {
    // console.log(data);

    // If you add a new metric, remember add it in helpers/filters.js
    let returnData = {
        ABWNotCancelled: 0,
        ABWCancelled: 0,
        ABWTotal: 0,

        // BWNotCancelled: 0,
        // BWCancelled: 0,
        BWTotal: 0,

        ALoSNotCancelled: 0,
        ALoSCancelled: 0,
        ALoSTotal: 0,

        ADRNotCancelled: 0,
        ADRCancelled: 0,
        ADRTotal: 0,

        // Commission

        commissionNotCancelled: 0,
        commissionCancelled: 0,
        commissionTotal: 0,

        commissionPercentageFromRooms: 0,

        commissionVouchers: 0,
        commissionPercentageFromVouchers: 0,

        //

        bookingsNotCancelled: 0,
        bookingsCancelled: 0,
        bookingsTotal: 0,
        bookingsRooms: 0,

        revenueNotCancelled: 0,
        revenueCancelled: 0,
        revenueTotal: 0,
        revenueVouchers: 0,
        revenueRoom: 0,
        revenueExtras: 0,
        revenueRoomAndExtras: 0,

        roomNightsNotCancelled: 0,
        roomNightsCancelled: 0,
        roomNightsTotal: 0,

        revenueNettNotCancelled: 0,
        revenueNettCancelled: 0,
        revenueNettTotal: 0,

        vouchersCount: 0,
        vouchersBookings: 0,
        voucherMin: Number.NaN,
        voucherMax: Number.NaN,
        cancellationRate: 0,
    };

    let breakfast = 0;
    let tax = 0;

    if (hotelID) {
        breakfast = HOTELS_INFO[hotelID].breakfast ?? 16;
        tax = HOTELS_INFO[hotelID].tax ?? 0.9;
    } else {
        breakfast = 16;
        tax = 0.9;
    }

    let aux_daily_rate_total = 0;
    let aux_room_nights_array_total = [];
    let aux_booking_windows_array_total = [];

    let aux_daily_rate = 0;
    let aux_room_nights_array_not_cancelled = [];
    let aux_booking_windows_array = [];

    let aux_daily_rate_cancelled = 0;
    let aux_room_nights_array_cancelled = [];
    let aux_booking_windows_array_cancelled = [];

    // console.log(data);

    if (data.length > 0) {
        data.forEach(
            ({
                // AroComissionPercentage,
                AroTotal,

                CancelStatus,
                RoomNights,
                RoomRevenue,
                TotalPrice,
                ExtrasCharge,
                BookingWindows,
                DailyRate,
                Type,
                VoucherCount,
            }) => {
                let rN = TotalPrice * tax - breakfast;

                // Not Cancelled
                if (CancelStatus === 0) {
                    // Not Cancelled Vouchers
                    if (Type === "Voucher") {
                        // New

                        returnData.commissionVouchers =
                            returnData.commissionVouchers + AroTotal;

                        // returnData.commissionPercentageVouchers =
                        //     returnData.commissionPercentageVouchers +
                        //     AroComissionPercentage;

                        //

                        returnData.vouchersCount =
                            returnData.vouchersCount + VoucherCount;
                        returnData.vouchersBookings =
                            returnData.vouchersBookings + 1;

                        returnData.revenueVouchers =
                            returnData.revenueVouchers + TotalPrice;

                        if (Number.isNaN(returnData.voucherMin))
                            returnData.voucherMin = Number.MAX_SAFE_INTEGER;
                        if (Number.isNaN(returnData.voucherMax))
                            returnData.voucherMax = Number.MIN_SAFE_INTEGER;
                        returnData.voucherMin = Math.min(
                            TotalPrice,
                            returnData.voucherMin
                        );
                        returnData.voucherMax = Math.max(
                            TotalPrice,
                            returnData.voucherMax
                        );
                    }
                    // Not Cancelled Special Offers
                    else {
                        returnData.bookingsRooms = returnData.bookingsRooms + 1;
                        returnData.bookingsNotCancelled =
                            returnData.bookingsNotCancelled + 1;

                        aux_room_nights_array_not_cancelled.push(RoomNights);
                        aux_booking_windows_array.push(BookingWindows);
                        aux_booking_windows_array_total.push(BookingWindows);
                        aux_room_nights_array_total.push(RoomNights);
                    }

                    // New

                    returnData.commissionNotCancelled =
                        returnData.commissionNotCancelled + AroTotal;

                    // returnData.commissionPercentageNotCancelled =
                    //     returnData.commissionPercentageNotCancelled +
                    //     AroComissionPercentage;

                    //

                    returnData.revenueNotCancelled =
                        returnData.revenueNotCancelled + TotalPrice;
                    returnData.revenueNettNotCancelled =
                        returnData.revenueNettNotCancelled + rN;
                    aux_daily_rate += DailyRate;
                }
                // Cancelled, only could be Special Offers
                else {
                    // New

                    returnData.commissionCancelled =
                        returnData.commissionCancelled + AroTotal;

                    // returnData.commissionPercentageCancelled =
                    //     returnData.commissionPercentageCancelled +
                    //     AroComissionPercentage;

                    //

                    returnData.bookingsRooms = returnData.bookingsRooms + 1;
                    returnData.bookingsCancelled =
                        returnData.bookingsCancelled + 1;
                    returnData.revenueCancelled =
                        returnData.revenueCancelled + TotalPrice;
                    returnData.revenueNettCancelled =
                        returnData.revenueNettCancelled + rN;
                    aux_daily_rate_cancelled += DailyRate;

                    aux_room_nights_array_cancelled.push(RoomNights);
                    aux_booking_windows_array_cancelled.push(BookingWindows);
                    aux_booking_windows_array_total.push(BookingWindows);
                    aux_room_nights_array_total.push(RoomNights);
                }

                // Common. Special offer and Vouchers cancelled or not cancelled

                // New

                returnData.commissionTotal =
                    returnData.commissionTotal + AroTotal;

                // returnData.commissionPercentageTotal =
                //     returnData.commissionPercentageTotal +
                //     AroComissionPercentage;

                //

                returnData.bookingsTotal = returnData.bookingsTotal + 1;

                returnData.revenueTotal = returnData.revenueTotal + TotalPrice;
                returnData.revenueNettTotal = returnData.revenueNettTotal + rN;
                returnData.revenueRoom =
                    returnData.revenueRoom + (RoomRevenue ?? 0);
                returnData.revenueExtras =
                    returnData.revenueExtras + (ExtrasCharge ?? 0);

                // Cancel or not cancelled Special Offers
                // if (Type === "Special Offer") {
                //     aux_daily_rate_total += DailyRate;
                // }
            }
        );

        let totalCommission =
            returnData.commissionTotal + returnData.commissionVouchers;

        returnData.commissionPercentageFromRooms = round(
            (returnData.commissionTotal / totalCommission) * 100,
            2
        );

        returnData.commissionPercentageFromVouchers = round(
            (returnData.commissionVouchers / totalCommission) * 100,
            2
        );

        returnData.revenueRoomAndExtras =
            returnData.revenueRoom + returnData.revenueExtras;

        returnData.roomNightsCancelled = aux_room_nights_array_cancelled.reduce(
            (p, c) => p + c,
            0
        );
        returnData.roomNightsNotCancelled =
            aux_room_nights_array_not_cancelled.reduce((p, c) => p + c, 0);
        returnData.roomNightsTotal = aux_room_nights_array_total.reduce(
            (p, c) => p + c,
            0
        );

        // ADR
        let auxADRCancelled = round(
            returnData.revenueCancelled / returnData.roomNightsCancelled,
            2
        );
        returnData.ADRCancelled = isNaN(auxADRCancelled) ? 0 : auxADRCancelled;

        let auxADRNotCancelled = round(
            returnData.revenueNotCancelled / returnData.roomNightsNotCancelled,
            2
        );
        returnData.ADRNotCancelled = isNaN(auxADRNotCancelled)
            ? 0
            : auxADRNotCancelled;

        let auxADRTotal = round(
            returnData.revenueRoom / returnData.roomNightsTotal,
            2
        );
        returnData.ADRTotal = isNaN(auxADRTotal) ? 0 : auxADRTotal;

        // ROUND Values

        // Room Nights
        returnData.roomNightsNotCancelled = round(
            returnData.roomNightsNotCancelled,
            0
        );
        returnData.roomNightsCancelled = round(
            returnData.roomNightsCancelled,
            0
        );
        returnData.roomNightsTotal = round(returnData.roomNightsTotal, 0);

        // Revenue
        returnData.revenueNotCancelled = round(
            returnData.revenueNotCancelled,
            0
        );
        returnData.revenueNettNotCancelled = round(
            returnData.revenueNettNotCancelled,
            0
        );
        returnData.revenueCancelled = round(returnData.revenueCancelled, 0);
        returnData.revenueNettCancelled = round(
            returnData.revenueNettCancelled,
            0
        );
        returnData.revenueTotal = round(returnData.revenueTotal, 0);
        returnData.revenueExtras = round(returnData.revenueExtras, 0);
        returnData.revenueRoomAndExtras = round(
            returnData.revenueRoomAndExtras,
            0
        );
        returnData.revenueVouchers = round(returnData.revenueVouchers, 0);
        returnData.revenueNettTotal = round(returnData.revenueNettTotal, 0);

        // ABW
        returnData.ABWNotCancelled = round(
            aux_booking_windows_array.reduce((p, c) => p + c, 0) /
                (aux_booking_windows_array.length || 1),
            0
        );
        returnData.ABWCancelled = round(
            aux_booking_windows_array_cancelled.reduce((p, c) => p + c, 0) /
                (aux_booking_windows_array_cancelled.length || 1),
            0
        );
        returnData.ABWTotal = round(
            aux_booking_windows_array_total.reduce((p, c) => p + c, 0) /
                (aux_booking_windows_array_total.length || 1),
            0
        );

        // BWTotal
        returnData.BWTotal = aux_booking_windows_array_total.reduce(
            (p, c) => p + c,
            0
        );

        // ALoS
        returnData.ALoSNotCancelled = round(
            aux_room_nights_array_not_cancelled.reduce((p, c) => p + c, 0) /
                (aux_room_nights_array_not_cancelled.length || 1),
            2
        );
        returnData.ALoSCancelled = round(
            aux_room_nights_array_cancelled.reduce((p, c) => p + c, 0) /
                (aux_room_nights_array_cancelled.length || 1),
            2
        );
        returnData.ALoSTotal = round(
            aux_room_nights_array_total.reduce((p, c) => p + c, 0) /
                (aux_room_nights_array_total.length || 1),
            2
        );
    }

    return returnData;
};

export const processCommission = (data) => {
    // console.log(data);

    // If you add a new metric, remember add it in helpers/filters.js
    let returnData = {
        // Commission

        commissionNotCancelled: 0,
        commissionCancelled: 0,
        commissionTotal: 0,

        commissionRoomsNotCancelled: 0, // commissionNotCancelled - commissionVouchers
        commissionRoomsCancelled: 0, // commissionCancelled
        commissionRoomsTotal: 0, // commissionTotal - commissionVouchers

        commissionPercentageFromRooms: 0,

        commissionVouchers: 0,
        commissionPercentageFromVouchers: 0,

        //
    };

    if (data.length > 0) {
        data.forEach(
            ({
                // AroComissionPercentage,
                AroTotal,
                CancelStatus,
                ResTypeID,
            }) => {
                // Not Cancelled
                if (CancelStatus === 0) {
                    // Not Cancelled Vouchers
                    if (ResTypeID === 4) {
                        // New

                        returnData.commissionVouchers =
                            returnData.commissionVouchers + AroTotal;

                        // returnData.commissionPercentageVouchers =
                        //     returnData.commissionPercentageVouchers +
                        //     AroComissionPercentage;

                        //
                    }

                    // New

                    returnData.commissionNotCancelled =
                        returnData.commissionNotCancelled + AroTotal;

                    // returnData.commissionPercentageNotCancelled =
                    //     returnData.commissionPercentageNotCancelled +
                    //     AroComissionPercentage;

                    //
                }
                // Cancelled, only could be Special Offers
                else {
                    // New

                    returnData.commissionCancelled =
                        returnData.commissionCancelled + AroTotal;

                    // returnData.commissionPercentageCancelled =
                    //     returnData.commissionPercentageCancelled +
                    //     AroComissionPercentage;

                    //
                }

                // Common. Special offer and Vouchers cancelled or not cancelled

                // New

                returnData.commissionTotal =
                    returnData.commissionTotal + AroTotal;

                // returnData.commissionPercentageTotal =
                //     returnData.commissionPercentageTotal +
                //     AroComissionPercentage;

                //
            }
        );

        let totalCommission =
            returnData.commissionTotal + returnData.commissionVouchers;

        returnData.commissionPercentageFromRooms = round(
            (returnData.commissionTotal / totalCommission) * 100,
            2
        );

        returnData.commissionPercentageFromVouchers = round(
            (returnData.commissionVouchers / totalCommission) * 100,
            2
        );

        returnData.commissionRoomsNotCancelled = round(
            returnData.commissionNotCancelled - returnData.commissionVouchers,
            2
        );

        returnData.commissionRoomsCancelled = round(
            returnData.commissionCancelled,
            2
        );

        returnData.commissionRoomsTotal = round(
            returnData.commissionTotal - returnData.commissionVouchers,
            2
        );
    }

    return returnData;
};

// Process a summary for Bookings, Revenue, Room Nights, ABW, ALoS & ADR and details for each day of the month
export const processMonth = (data, sourceData, year, month, hotelID) => {
    let dataTemplate = {
        ABW: 0,
        ABWCancelled: 0,
        ABWTotal: 0,

        ALoS: 0,
        ALoSCancelled: 0,
        ALoSTotal: 0,

        ADR: 0,
        ADRCancelled: 0,
        ADRTotal: 0,

        bookings: 0,
        bookingsCancelled: 0,
        bookingsTotal: 0,

        revenue: 0,
        revenueCancelled: 0,
        revenueTotal: 0,

        roomNights: 0,
        roomNightsCancelled: 0,
        roomNightsTotal: 0,

        revenueNett: 0,
        revenueNettCancelled: 0,
        revenueNettTotal: 0,

        cancellationRate: 0,
    };

    let breakfast = 0;
    let tax = 0;

    if (hotelID) {
        breakfast = HOTELS_INFO[hotelID].breakfast ?? 16;
        tax = HOTELS_INFO[hotelID].tax ?? 0.9;
    } else {
        breakfast = 16;
        tax = 0.9;
    }

    let returnData = {};

    returnData = JSON.parse(JSON.stringify(dataTemplate));

    // Get days of the month
    const lastDay = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
    let days = {};
    for (let i = 1; i <= lastDay; i++) {
        days[i] = {
            ABW: 0,
            ABWCancelled: 0,
            ABWTotal: 0,

            ALoS: 0,
            ALoSCancelled: 0,
            ALoSTotal: 0,

            ADR: 0,
            ADRCancelled: 0,
            ADRTotal: 0,

            bookings: 0,
            bookingsCancelled: 0,
            bookingsTotal: 0,

            revenue: 0,
            revenueCancelled: 0,
            revenueTotal: 0,

            revenueNett: 0,
            revenueNettCancelled: 0,
            revenueNettTotal: 0,

            roomNights: 0,
            roomNightsCancelled: 0,
            roomNightsTotal: 0,

            cancellationRate: 0,
        };
    }
    // Set Days of the month
    returnData.Details = days;

    if (data.length > 0) {
        let firstDay = data[0].CheckOut;
        let lastDay = data[0].CheckOut;
        data.forEach((element) => {
            if (element.CancelStatus === 0) {
                if (element.CheckOut > lastDay) lastDay = element.CheckOut;
                if (element.CheckOut < firstDay) firstDay = element.CheckOut;
            }
        });

        // Aux data template set
        let auxTemplate = {
            bookings: 0,
            vouchers: 0,
            revenue: 0,
            revenue_nett: 0,
            daily_rate: 0,
            room_nights_array: [],
            booking_windows_array: [],
        };

        let auxData = {
            uncancelled: JSON.parse(JSON.stringify(auxTemplate)),
            cancelled: JSON.parse(JSON.stringify(auxTemplate)),
            totals: JSON.parse(JSON.stringify(auxTemplate)),
            details: { uncancelled: {}, cancelled: {}, totals: {} },
        };

        Object.keys(days).forEach((day) => {
            auxData.details.uncancelled[day] = JSON.parse(
                JSON.stringify(auxTemplate)
            );
            auxData.details.cancelled[day] = JSON.parse(
                JSON.stringify(auxTemplate)
            );
            auxData.details.totals[day] = JSON.parse(
                JSON.stringify(auxTemplate)
            );
        });

        //  _   _                          _ _        _
        // | | | |_ _  __ __ _ _ _  __ ___| | |___ __| |
        // | |_| | ' \/ _/ _` | ' \/ _/ -_) | / -_) _` |
        //  \___/|_||_\__\__,_|_||_\__\___|_|_\___\__,_|

        const uncancelled = data.filter(
            ({ CancelStatus }) => CancelStatus === 0
        );

        uncancelled.forEach((element) => {
            // Summary & details

            let day = new Date(element[sourceData]).getUTCDate();

            if (element.Type === "Voucher") {
                auxData.uncancelled.vouchers += element.VoucherCount;
                auxData.details.uncancelled[day].vouchers +=
                    element.VoucherCount;
            } else {
                // Uncancelled summary
                auxData.uncancelled.bookings++;
                auxData.uncancelled.room_nights_array.push(element.RoomNights);
                auxData.uncancelled.booking_windows_array.push(
                    element.BookingWindows
                );

                // Uncancelled details
                auxData.details.uncancelled[day].bookings++;
                auxData.details.uncancelled[day].room_nights_array.push(
                    element.RoomNights
                );
                auxData.details.uncancelled[day].booking_windows_array.push(
                    element.BookingWindows
                );

                // Totals summary
                auxData.totals.bookings++;
                auxData.totals.room_nights_array.push(element.RoomNights);
                auxData.totals.booking_windows_array.push(
                    element.BookingWindows
                );

                // Totals details
                auxData.details.totals[day].bookings++;
                auxData.details.totals[day].room_nights_array.push(
                    element.RoomNights
                );
                auxData.details.totals[day].booking_windows_array.push(
                    element.BookingWindows
                );
            }

            let rN = element.TotalPrice * tax - breakfast;

            // Uncancelled summary
            auxData.uncancelled.revenue += element.TotalPrice;
            auxData.uncancelled.revenue_nett += rN;
            auxData.uncancelled.daily_rate += element.DailyRate;

            // Uncancelled details
            auxData.details.uncancelled[day].revenue += element.TotalPrice;
            auxData.details.uncancelled[day].revenue_nett += element.rN;
            auxData.details.uncancelled[day].daily_rate += element.DailyRate;

            // Totals
            auxData.totals.revenue += element.TotalPrice;
            auxData.totals.revenue_nett += rN;
            auxData.totals.daily_rate += element.DailyRate;

            // Totals details
            auxData.details.totals[day].revenue += element.TotalPrice;
            auxData.details.totals[day].revenue_nett += rN;
            auxData.details.totals[day].daily_rate += element.DailyRate;
        });

        // Uncancelled Set
        returnData.bookings = auxData.uncancelled.bookings;

        returnData.revenue = round(auxData.uncancelled.revenue, 2);
        returnData.revenueNett = round(auxData.uncancelled.revenue_nett, 2);
        returnData.roomNights = auxData.uncancelled.room_nights_array.reduce(
            (p, c) => p + c,
            0
        );
        returnData.ALoS = round(
            auxData.uncancelled.room_nights_array.reduce((p, c) => p + c, 0) /
                auxData.uncancelled.room_nights_array.length || 1,
            2
        );
        returnData.ABW = round(
            auxData.uncancelled.booking_windows_array.reduce(
                (p, c) => p + c,
                0
            ) / auxData.uncancelled.booking_windows_array.length || 1,
            0
        );

        returnData.ADR = round(
            auxData.uncancelled.daily_rate / auxData.uncancelled.bookings,
            2
        );

        //      ___                  _ _        _
        //     / __|__ _ _ _  __ ___| | |___ __| |
        //    | (__/ _` | ' \/ _/ -_) | / -_) _` |
        //     \___\__,_|_||_\__\___|_|_\___\__,_|

        const cancelled = data.filter(({ CancelStatus }) => CancelStatus === 1);

        cancelled.forEach((element) => {
            let day = new Date(element[sourceData]).getUTCDate();

            let rN = element.TotalPrice * tax - breakfast;

            // Cancelled summary
            auxData.cancelled.bookings++;
            auxData.cancelled.room_nights_array.push(element.RoomNights);
            auxData.cancelled.booking_windows_array.push(
                element.BookingWindows
            );
            auxData.cancelled.revenue += element.TotalPrice;
            auxData.cancelled.revenue_nett += rN;
            auxData.cancelled.daily_rate += element.DailyRate;

            // Totals summary
            auxData.totals.bookings++;
            auxData.totals.room_nights_array.push(element.RoomNights);
            auxData.totals.booking_windows_array.push(element.BookingWindows);
            auxData.totals.revenue += element.TotalPrice;
            auxData.totals.revenue_nett += rN;
            auxData.totals.daily_rate += element.DailyRate;

            // Cancelled details
            auxData.details.cancelled[day].bookings++;
            auxData.details.cancelled[day].room_nights_array.push(
                element.RoomNights
            );
            auxData.details.cancelled[day].booking_windows_array.push(
                element.BookingWindows
            );
            auxData.details.cancelled[day].revenue += element.TotalPrice;
            auxData.details.cancelled[day].revenue_nett += rN;
            auxData.details.cancelled[day].daily_rate += element.DailyRate;

            // Totals details
            auxData.details.totals[day].bookings++;
            auxData.details.totals[day].room_nights_array.push(
                element.RoomNights
            );
            auxData.details.totals[day].booking_windows_array.push(
                element.BookingWindows
            );
            auxData.details.totals[day].revenue += element.TotalPrice;
            auxData.details.totals[day].revenue_nett += rN;
            auxData.details.totals[day].daily_rate += element.DailyRate;
        });
        // Cancelled Set

        returnData.bookingsCancelled = auxData.cancelled.bookings;

        returnData.revenueCancelled = round(auxData.cancelled.revenue, 2);
        returnData.revenueNettCancelled = round(
            auxData.cancelled.revenue_nett,
            2
        );
        returnData.roomNightsCancelled =
            auxData.cancelled.room_nights_array.reduce((p, c) => p + c, 0);
        returnData.ALoSCancelled = round(
            auxData.cancelled.room_nights_array.reduce((p, c) => p + c, 0) /
                (auxData.cancelled.room_nights_array.length || 1),
            2
        );
        returnData.ABWCancelled = round(
            auxData.cancelled.booking_windows_array.reduce((p, c) => p + c, 0) /
                (auxData.cancelled.booking_windows_array.length || 1),
            0
        );

        returnData.ADRCancelled =
            auxData.cancelled.daily_rate / auxData.cancelled.bookings;

        //  _____    _        _
        // |_   _|__| |_ __ _| |
        //   | |/ _ \  _/ _` | |
        //   |_|\___/\__\__,_|_|
        returnData.revenueTotal = round(auxData.totals.revenue, 2);
        returnData.revenueNettTotal = round(auxData.totals.revenue_nett, 2);
        returnData.roomNightsTotal = auxData.totals.room_nights_array.reduce(
            (p, c) => p + c,
            0
        );

        returnData.ALoSTotal = round(
            auxData.totals.room_nights_array.reduce((p, c) => p + c, 0) /
                (auxData.totals.room_nights_array.length || 1),
            2
        );

        returnData.ABWTotal = round(
            auxData.totals.booking_windows_array.reduce((p, c) => p + c, 0) /
                (auxData.totals.booking_windows_array.length || 1),
            0
        );

        returnData.ADRTotal =
            auxData.totals.daily_rate / auxData.totals.bookings;

        returnData.bookingsTotal =
            auxData.totals.bookings + auxData.uncancelled.vouchers;

        returnData.cancellationRate =
            returnData.bookingsCancelled / auxData.totals.bookings;

        // Details Set
        Object.keys(days).forEach((day) => {
            // Details uncancelled
            let aux_room_nights = auxData.details.uncancelled[
                day
            ].room_nights_array.reduce((p, c) => p + c, 0);

            returnData.Details[day].revenue =
                auxData.details.uncancelled[day].revenue;

            returnData.Details[day].revenueNett =
                auxData.details.uncancelled[day].revenue_nett;

            returnData.Details[day].roomNights = aux_room_nights;

            returnData.Details[day].ALoS = round(
                auxData.details.uncancelled[day].room_nights_array.reduce(
                    (p, c) => p + c,
                    0
                ) /
                    (auxData.details.uncancelled[day].room_nights_array
                        .length || 1),
                2
            );
            returnData.Details[day].ABW = round(
                auxData.details.uncancelled[day].booking_windows_array.reduce(
                    (p, c) => p + c,
                    0
                ) /
                    (auxData.details.uncancelled[day].booking_windows_array
                        .length || 1),
                0
            );

            returnData.Details[day].ADR =
                auxData.details.uncancelled[day].daily_rate /
                auxData.details.uncancelled[day].bookings;

            returnData.Details[day].bookings =
                auxData.details.uncancelled[day].bookings +
                auxData.details.uncancelled[day].vouchers;

            // Details cancelled
            let aux_room_nights_cancelled = auxData.details.cancelled[
                day
            ].room_nights_array.reduce((p, c) => p + c, 0);

            returnData.Details[day].revenueCancelled =
                auxData.details.cancelled[day].revenue;

            returnData.Details[day].revenueNettCancelled =
                auxData.details.cancelled[day].revenue_nett;

            returnData.Details[day].roomNightsCancelled =
                aux_room_nights_cancelled;

            returnData.Details[day].ALoSCancelled = round(
                auxData.details.cancelled[day].room_nights_array.reduce(
                    (p, c) => p + c,
                    0
                ) /
                    (auxData.details.cancelled[day].room_nights_array.length ||
                        1),
                2
            );
            returnData.Details[day].ABWCancelled = round(
                auxData.details.cancelled[day].booking_windows_array.reduce(
                    (p, c) => p + c,
                    0
                ) /
                    (auxData.details.cancelled[day].booking_windows_array
                        .length || 1),
                0
            );

            returnData.Details[day].ADRCancelled =
                auxData.details.cancelled[day].daily_rate /
                auxData.details.cancelled[day].bookings;

            returnData.Details[day].bookingsCancelled =
                auxData.details.cancelled[day].bookings;

            // Details totals
            let aux_room_nights_totals = auxData.details.totals[
                day
            ].room_nights_array.reduce((p, c) => p + c, 0);

            returnData.Details[day].revenueTotal =
                auxData.details.totals[day].revenue;

            returnData.Details[day].revenueNettTotal =
                auxData.details.totals[day].revenue_nett;

            returnData.Details[day].roomNightsTotal = aux_room_nights_totals;

            returnData.Details[day].ALoSTotal = round(
                auxData.details.totals[day].room_nights_array.reduce(
                    (p, c) => p + c,
                    0
                ) / (auxData.details.totals[day].room_nights_array.length || 1),
                2
            );
            returnData.Details[day].ABWTotal = round(
                auxData.details.totals[day].booking_windows_array.reduce(
                    (p, c) => p + c,
                    0
                ) /
                    (auxData.details.totals[day].booking_windows_array.length ||
                        1),
                0
            );

            returnData.Details[day].ADRTotal =
                auxData.details.totals[day].daily_rate /
                auxData.details.totals[day].bookings;

            returnData.Details[day].bookingsTotal =
                auxData.details.totals[day].bookings +
                auxData.details.totals[day].vouchers;
        });
    }

    return returnData;
};

// Process a summary for Bookings, Revenue, Room Nights, ABW, ALoS & ADR and details for each day of the month
export const processMonthLostRevenue = (data, sourceData, year, month) => {
    let dataTemplate = {
        TotalSearchCount: 0,
        TotalSessionCount: 0,
        NoAvailabilitySearchCount: 0,
        NoAvailabilitySessionCount: 0,
        LostRevenue: 0,
    };

    let TopSearchesElement = null;
    let TopSearches = -1;

    let returnData = {};

    returnData = JSON.parse(JSON.stringify(dataTemplate));

    // Get days of the month
    const lastDay = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
    let days = {};
    for (let i = 1; i <= lastDay; i++) {
        days[i] = {
            TotalSearchCount: 0,
            TotalSessionCount: 0,
            NoAvailabilitySearchCount: 0,
            NoAvailabilitySessionCount: 0,
            LostRevenue: 0,
        }; //JSON.parse(JSON.stringify(dataTemplate));
    }
    // Set Days of the month
    returnData.Details = days;

    if (data.length > 0) {
        let firstDay = data[0].SearchDate;
        let lastDay = data[0].SearchDate;
        data.forEach((element) => {
            if (element.SearchDate > lastDay) lastDay = element.SearchDate;
            if (element.SearchDate < firstDay) firstDay = element.SearchDate;
        });

        // Aux data template set
        let auxTemplate = {
            TotalSearchCount: 0,
            TotalSessionCount: 0,
            NoAvailabilitySearchCount: 0,
            NoAvailabilitySessionCount: 0,
            LostRevenue: 0,
        };
        let auxData = {
            normals: JSON.parse(JSON.stringify(auxTemplate)),
            details: { normals: {} },
        };
        Object.keys(days).forEach((day) => {
            auxData.details.normals[day] = JSON.parse(
                JSON.stringify(auxTemplate)
            );
        });
        // Normals Get
        data.forEach((element, index) => {
            // Normals
            auxData.normals.TotalSearchCount += element.TotalSearchCount;
            auxData.normals.TotalSessionCount += element.TotalSessionCount;

            auxData.normals.NoAvailabilitySearchCount +=
                element.NoAvailabilitySearchCount;
            auxData.normals.NoAvailabilitySessionCount +=
                element.NoAvailabilitySessionCount;

            auxData.normals.LostRevenue += element.LostRevenue;

            // Details Normals
            let day = new Date(element[sourceData]).getUTCDate();

            auxData.details.normals[day].TotalSearchCount +=
                element.TotalSearchCount;
            auxData.details.normals[day].TotalSessionCount +=
                element.TotalSessionCount;
            auxData.details.normals[day].NoAvailabilitySearchCount +=
                element.NoAvailabilitySearchCount;

            auxData.details.normals[day].NoAvailabilitySessionCount +=
                element.NoAvailabilitySessionCount;
            auxData.details.normals[day].LostRevenue += element.LostRevenue;

            if (element.TotalSearchCount > TopSearches) {
                TopSearches = element.TotalSearchCount;
                TopSearchesElement = element;
            }
        });

        // Normals Set
        returnData.TotalSearchCount = round(
            auxData.normals.TotalSearchCount,
            0
        );
        returnData.TotalSessionCount = round(
            auxData.normals.TotalSessionCount,
            0
        );
        returnData.NoAvailabilitySearchCount = round(
            auxData.normals.NoAvailabilitySearchCount,
            0
        );
        returnData.NoAvailabilitySessionCount = round(
            auxData.normals.NoAvailabilitySessionCount,
            0
        );
        returnData.LostRevenue = round(auxData.normals.LostRevenue, 0);

        returnData.TopSearchesElement = getDateString(
            TopSearchesElement.SearchDate
        );

        // Details Set
        Object.keys(days).forEach((day) => {
            // Details Normals Set
            returnData.Details[day].TotalSearchCount =
                auxData.details.normals[day].TotalSearchCount;
            returnData.Details[day].TotalSessionCount =
                auxData.details.normals[day].TotalSessionCount;
            returnData.Details[day].NoAvailabilitySearchCount =
                auxData.details.normals[day].NoAvailabilitySearchCount;

            returnData.Details[day].NoAvailabilitySessionCount =
                auxData.details.normals[day].NoAvailabilitySessionCount;

            returnData.Details[day].LostRevenue =
                auxData.details.normals[day].LostRevenue;
        });
    }

    return returnData;
};

export const processLostRevenue = (data) => {
    let returnData = {
        TotalSearchCount: 0,
        TotalSessionCount: 0,
        NoAvailabilitySearchCount: 0,
        NoAvailabilitySessionCount: 0,
        LostRevenue: 0,
    };

    let TopSearchesElement = null;
    let TopSearches = -1;

    if (data.length > 0) {
        let firstDay = data[0].SearchDate;
        let lastDay = data[0].SearchDate;
        data.forEach((element) => {
            if (element.SearchDate > lastDay) lastDay = element.SearchDate;
            if (element.SearchDate < firstDay) firstDay = element.SearchDate;
        });

        let aux_TotalSearchCount = 0;
        let aux_TotalSessionCount = 0;
        let aux_NoAvailabilitySearchCount = 0;
        let aux_NoAvailabilitySessionCount = 0;
        let aux_LostRevenue = 0;

        data.forEach((element) => {
            aux_TotalSearchCount += element.TotalSearchCount;
            aux_TotalSessionCount += element.TotalSessionCount;
            aux_NoAvailabilitySearchCount += element.NoAvailabilitySearchCount;
            aux_NoAvailabilitySessionCount +=
                element.NoAvailabilitySessionCount;
            aux_LostRevenue += element.LostRevenue;

            if (element.TotalSearchCount > TopSearches) {
                TopSearches = element.TotalSearchCount;
                TopSearchesElement = element;
            }
        });

        returnData.TotalSearchCount = round(aux_TotalSearchCount, 0);
        returnData.TotalSessionCount = round(aux_TotalSessionCount, 0);
        returnData.NoAvailabilitySearchCount = round(
            aux_NoAvailabilitySearchCount,
            0
        );
        returnData.NoAvailabilitySessionCount = round(
            aux_NoAvailabilitySessionCount,
            0
        );
        returnData.LostRevenue = round(aux_LostRevenue, 0);

        returnData.TopSearchesElement = getDateString(
            TopSearchesElement.SearchDate
        );
    }
    // console.log(returnData);

    return returnData;
};

export const processEvents = (data) => {
    let auxEvents = {};

    data.forEach((event) => {
        const diffFromToDays = daysBetween(
            dateToUTC(event.FromDate),
            dateToUTC(event.ToDate)
        );
        const diffFromDeadlineDays = daysBetween(
            dateToUTC(event.FromDate),
            dateToUTC(event.Deadline)
        );

        let excludeDates = {};
        if (event.RepeatRule && event.RepeatRule.split(" ").length === 2) {
            event.RepeatRule.split(" ")[1]
                .slice(8)
                .split(",")
                .forEach((date) => {
                    excludeDates[date] = true;
                });
        }
        event.RepeatedDates.forEach((repeatDate) => {
            if (!excludeDates.hasOwnProperty(repeatDate)) {
                const fromDate = new Date(repeatDate);
                const year = fromDate.getUTCFullYear();
                const month = fromDate.getUTCMonth();
                const day = fromDate.getUTCDate();
                const dateString = newDateUTC(year, month, day)
                    .toISOString()
                    .slice(0, 10);
                const toDate = newDateUTC(year, month, day + diffFromToDays);
                const deadlineDate = newDateUTC(
                    year,
                    month,
                    day + diffFromDeadlineDays
                );
                let auxEvent = Object.assign({}, event);
                auxEvent.FromDate = fromDate;
                auxEvent.ToDate = toDate;
                auxEvent.Deadline = deadlineDate;
                if (auxEvents.hasOwnProperty(dateString)) {
                    auxEvents[dateString].push(auxEvent);
                } else {
                    auxEvents[dateString] = [auxEvent];
                }
            }
        });
    });

    return auxEvents;
};

const getDateString = (e) => {
    try {
        const dateString = new Date(e).toDateString().slice(4, 11);
        return dateString;
    } catch (e) {
        console.log(e);

        return "Error";
    }
};
