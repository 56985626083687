import { useEffect } from "react";

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";

import DropdownButton from "./DropdownButton";
import Icons from "../helpers/icons";
import Colours from "../helpers/colours";

export const transformArrToOptions = (arr) => {
    // ["value1", "value2", ....] = {value1: "value1", value2: "value2", ....}
    return arr.reduce((acc, value) => {
        acc[value] = value;
        return acc;
    }, {});
};

export const FilterChips = ({
    activeFilters,
    setActiveFilters,
    filters = {},
}) => {
    const handleRemove = (category, filter) => {
        let auxActiveFilters = { ...activeFilters };
        const filterIndex = ((activeFilters ?? {})[category] ?? []).indexOf(
            filter
        );

        if (filterIndex !== -1) {
            auxActiveFilters[category].splice(filterIndex, 1);
        } else {
            console.error("Error deleting filter: wasn't found: ", {
                activeFilters,
                category,
                filter,
            });
        }
        setActiveFilters(auxActiveFilters);
    };

    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems={"center"}
            sx={{
                overflowX: "auto",
                // Estilos de la barra de desplazamiento
                "&::-webkit-scrollbar": {
                    width: "12px", // Ancho de la barra de desplazamiento
                    height: "6px",
                    margin: "2px",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1", // Color de fondo de la barra
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "#ccc", // Color del pulgar de desplazamiento
                    borderRadius: "6px",
                    "&:hover": {
                        background: "#aaa", // Color del pulgar al pasar el mouse
                    },
                },
                padding: "2px 0",
            }}
        >
            {Object.values(activeFilters).some(
                (filters) => filters.length > 0
            ) && (
                <>
                    <Button
                        variant="outlined"
                        sx={{
                            color: Colours.greyIcon,
                            textTransform: "none",
                            borderRadius: 10,
                            borderColor: Colours.greyIcon,
                            padding: 0,
                            paddingX: 1,
                            fontSize: "14.85px",
                            height: "23px",
                        }}
                        onClick={() => {
                            const auxActiveFilters = Object.fromEntries(
                                Object.keys(activeFilters).map((key) => [
                                    key,
                                    [],
                                ])
                            );

                            setActiveFilters(auxActiveFilters);
                        }}
                    >
                        Clear filters
                    </Button>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            borderColor: Colours.navbarBottomLine,
                        }}
                    />
                </>
            )}

            {Object.keys(activeFilters).map((filter) => {
                return activeFilters[filter].map((option) => (
                    <Tooltip
                        title={
                            ((filters[filter] ?? {}).label ?? filter) +
                            ": " +
                            option
                        }
                        key={filter + "-" + option}
                    >
                        <Chip
                            sx={{ cursor: "pointer" }}
                            size="small"
                            label={
                                ((filters[filter] ?? {}).options ?? {})[
                                    option
                                ] ?? option
                            }
                            onDelete={() => handleRemove(filter, option)}
                            onDoubleClick={() => handleRemove(filter, option)}
                        />
                    </Tooltip>
                ));
            })}
        </Stack>
    );
};

const FilterDropdown = ({
    filters = {
        category: {
            label: "Category",
            options: { opt1: "Category 1", opt2: "Category 2" },
        },
    },
    buttonProps = { Icon: Icons.Filter, title: "" },
    title = "",
    activeFilters = {},
    setActiveFilters,
    downMD = false,
    downSM = false,
}) => {
    if (Object.keys(filters).length > 0)
        return (
            <DropdownButton
                {...buttonProps}
                header={{
                    closeButton: true,
                    component: (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            justifyItems={"space-between"}
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="h6">{title}</Typography>
                            {Object.values(activeFilters).some(
                                (filters) => filters.length > 0
                            ) && (
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: Colours.greyIcon,
                                        textTransform: "none",
                                        borderRadius: 10,
                                        borderColor: Colours.greyIcon,
                                        paddingY: 0,
                                    }}
                                    onClick={() => {
                                        const auxActiveFilters =
                                            Object.fromEntries(
                                                Object.keys(filters).map(
                                                    (key) => [key, []]
                                                )
                                            );
                                        setActiveFilters(auxActiveFilters);
                                    }}
                                >
                                    Clear filters
                                </Button>
                            )}
                        </Stack>
                    ),
                }}
            >
                {Object.keys(filters).map((filter) => {
                    return (
                        <Autocomplete
                            sx={{
                                width: downMD
                                    ? downSM
                                        ? "200px"
                                        : "300px"
                                    : "400px",
                            }}
                            key={filter + "-filter-autocomplete"}
                            id={filter + "-filter-autocomplete"}
                            options={Object.keys(filters[filter].options ?? [])}
                            multiple
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                                filters[filter].options[option] ?? ""
                            }
                            value={activeFilters[filter] ?? []} // array of option keys: [ opt1, opt2... ]
                            renderOption={(props, optionKey) => {
                                let selected = (
                                    activeFilters[filter] ?? []
                                ).includes(optionKey);
                                return (
                                    <li {...props}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {filters[filter].options[optionKey]}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={filters[filter].label ?? ""}
                                />
                            )}
                            onChange={(event, newValue) => {
                                const auxActiveFilters = { ...activeFilters };
                                auxActiveFilters[filter] = newValue;
                                setActiveFilters(auxActiveFilters);
                            }}
                        />
                    );
                })}
            </DropdownButton>
        );
    else return <></>;
};

export default FilterDropdown;
