import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Checkbox, Stack } from "@mui/material";

import HoverPaper from "./shared/HoverPaper";
import Colours from "../helpers/colours";

const format = (key, value) => {
    if (["CheckIn", "CheckOut", "ResDate"].includes(key) && value) {
        return new Date(value).toISOString().slice(0, 16).replace("T", " ");
    }
    return value;
};

const TableReservations = ({ data }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setData] = useState([]);

    const [filterOtherPeriods, setFilterOtherPeriods] = useState(false);

    useEffect(() => {
        if (data) {
            const allColumns = Object.keys(Object.values(data)[0].data[0]).map(
                (c) => ({
                    field: c,
                    headerName: c,
                    flex: 1,
                    renderCell: (params) => format(c, params.value),
                })
            );
            setColumns(allColumns);

            const allRows = [];
            Object.values(data).forEach((section) => {
                if (section.data && section.data.length > 0) {
                    allRows.push(...section.data);
                }
            });

            setData(
                allRows
                    .filter((d) => {
                        return !filterOtherPeriods || d.period.includes("this");
                    })
                    .map((r, index) => {
                        return { ...r, id: index };
                    })
            );
        }
    }, [data, filterOtherPeriods]);

    const [filterModel, setFilterModel] = useState({
        items: [
            {
                columnField: "period",
                operatorValue: "contains",
                value: "this",
            },
        ],
    });

    return (
        <div>
            <HoverPaper
                sx={{
                    height: "700px",
                    width: "100%",
                    p: 1,
                    backgroundColor: Colours.notificationCard,
                }}
            >
                <Stack sx={{ height: "100%" }}>
                    <Box>
                        <Checkbox
                            checked={filterOtherPeriods}
                            value={filterOtherPeriods}
                            onClick={() => {
                                setFilterOtherPeriods(!filterOtherPeriods);
                            }}
                        />
                        Filter other periods
                    </Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    AroTotal: false,
                                    BookingWindows: false,
                                    CancelStatus: false,
                                    DailyRate: false,
                                    DailyRoomRate: false,
                                    ExtrasCharge: false,
                                    HotelID: false,
                                    ResTypeID: false,
                                    ReservationID: false,
                                    RoomRevenue: false,
                                    SiteID: false,
                                    SiteName: false,
                                    VoucherCount: false,
                                    VoucherName: false,
                                    bookings: false,
                                    isHistorical: false,
                                    rn: false,
                                    sessions: false,
                                    Day: false,
                                    Month: false,
                                    Year: false,
                                    selectedDate: false,
                                },
                            },
                            filter: {
                                filterModel: {
                                    items: [
                                        {
                                            columnField: "period",
                                            operatorValue: "contains",
                                            value: "this",
                                        },
                                    ],
                                },
                            },
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) =>
                            setFilterModel(newFilterModel)
                        }
                        sx={{ border: 0 }}
                    />
                </Stack>
            </HoverPaper>
        </div>
    );
};

export default TableReservations;
